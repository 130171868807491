import React, { useState, useEffect} from "react"
import decode from 'jwt-decode'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
// Redux
import {useLocation, Link } from "react-router-dom"
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux';
// Redux actions
import { Logout } from "../../../../store/auth/actions";
import { getProfilesByUser} from '../../../../store/profile/actions';

const ProfileMenu = () => {
  const dispatch = useDispatch()
  const { profiles } = useSelector((state) => state.profiles)
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
  const location = useLocation()

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('profile')))
},[location])


useEffect(() => {
  dispatch(getProfilesByUser({ search: user?.result?._id}))
// eslint-disable-next-line react-hooks/exhaustive-deps
},[location])

// Logout Handler
  const LogoutHanlder = ()=>{
    dispatch(Logout())
    setUser(null)
 }

 useEffect(()=> {
  const token = user?.token
  if(token) {
      const decodedToken = decode(token)
      if(decodedToken.exp * 1000 < new Date().getTime()) LogoutHanlder()
  }
  // eslint-disable-next-line
}, [location, user]) //when location changes, set the user

  const [menu, setMenu] = useState(false)

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {profiles?.avatar && (
          <img
          className="rounded-circle header-profile-user"
          src={profiles?.avatar}
          alt={profiles?.firstName}
        />
          )}
          <span className="d-none d-xl-inline-block ms-2 me-1">{profiles?.firstName}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
          
        
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem>
          <Link to="/user/dashboard/settings">
            <i className="bx bx-wrench font-size-16 align-middle me-1"/>
            Settings
            </Link>
          </DropdownItem>
          <div className="dropdown-divider"/>
          <Link onClick={LogoutHanlder} to="/login" className="dropdown-item" >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
            <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default ProfileMenu;