import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import isEmpty from "../../utils/isEmpty"

const ForceRedirect = ({component: Component,layout: Layout,profile, ...rest}) => (

  <Route
    {...rest}
    render={props => {
      if (profile !== isEmpty &&  profile?.result?.role === 'USER') {
        return (
          <Redirect
           push to="/user/dashboard"
             replace />
        )
      } else{
        if(profile !== isEmpty && profile?.result?.role === 'ADMIN')
        return (
          <Redirect
           push to="/admin/dashboard"
             replace />
        )
      } 
     
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

ForceRedirect.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default ForceRedirect;
