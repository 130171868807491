import * as api from '../../api/index'
import {ALL_USERS, FETCH_USER, FETCH_USERS_BY_USER, APPROVE_USER, START_LOADING, END_LOADING } from './actionTypes'


export const fetchUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchUser(id);
    dispatch({ type: FETCH_USER, payload: { user: data } });
  } catch (error) {
    console.log(error);
  }
};

  export const fetchUsers = () => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING })
      const { data } = await api.fetchUsers();
      dispatch({ type: ALL_USERS, payload: data });
      dispatch({ type: END_LOADING })
  
    } catch (error) {
      console.log(error);
    } };


export const fetchUsersByUser =(searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING })

     const  { data: { data } } = await api.fetchUsersByUser(searchQuery)
    //const  { data } = await axiosInstance.get('/users');
  
      dispatch({ type: FETCH_USERS_BY_USER, payload: data });
      dispatch({ type: END_LOADING })
    } catch (error) {
      //console.log("Checking Error",error.response)
      
    }
  }

  // Approve a user
export const approveUser =(id, openSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING })
  const { data } = await api.approveUser(id)
  dispatch({ type: APPROVE_USER, payload: data})
  dispatch({ type: END_LOADING })
   openSnackbar("User approved successfully")
  } catch (error) {
      console.log(error)
  }
}