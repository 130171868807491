import { ERRORS } from "../errors/actionTypes";

const initialState = {}

export const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ERRORS:
        return action.payload
    default:
    return state
}
}
export default errorReducer;