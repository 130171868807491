import PropTypes from 'prop-types'
import React from 'react';
import { Col, Card, CardBody } from "reactstrap"

const MiniWidget = props => {
    return (
        <>
        {props.reports.map((report, key) => (
          <Col sm="4" key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs me-3">
                    <span style={{color:"#022b51"}} className="avatar-title rounded-circle bg-primary bg-soft font-size-18">
                      <i className={report.icon}/>
                    </span>
                  </div>
                  <h5 className="font-size-14 mb-0">{report.title}</h5>
                </div>
                <div className="text-muted mt-4">
                  <h4>
                    {report.value}{" "}
                  </h4>
                  <div className="d-flex">
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </>
    );
}

export default MiniWidget;

MiniWidget.propTypes = {
  reports: PropTypes.array
}
