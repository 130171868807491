import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'

import rootSagas from './sagas'
import rootReducer from './reducers'

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const middleWares = [sagaMiddleware, thunk]
const initialState = {}
const store = createStore(
 rootReducer,
 initialState,
 composeEnhancers(applyMiddleware(...middleWares))
)

sagaMiddleware.run(rootSagas)
export default store