import PropTypes from 'prop-types'
import React from "react"
import SnackbarProvider from 'react-simple-snackbar'
import { Switch,Redirect, BrowserRouter as Router} from "react-router-dom"
import { connect } from "react-redux"
// Import Routes all
import { adminRoutes, authRoutes, userRoutes, noAccessRoutes} from "./Routes/Routes"

// Import all middleware
import AdminMiddleware from "./Routes/Middleware/AdminMiddleware";
import UserMiddleware from "./Routes/Middleware/UserMiddleware"
import ForceRedirect from "./Routes/Middleware/ForceRedirect"
import NoaccessMiddleware from "./Routes/Middleware/NoaccessMiddleware";
// layouts Format
import AdminLayout from './components/AdminLayout'
import UserLayout from './components/UserLayout'
import NonAuthLayout from "./components/NonAuthLayout"

import { useSelector } from 'react-redux';
// Import scss
import "./assets/scss/theme.scss"


const App = () => {

 const auth = useSelector(state => state.auth)
//console.log(auth)

const profile = JSON.parse(localStorage.getItem('profile'))


  return (
    <> 
      <SnackbarProvider>
      <Router>
        <Switch>
        <Redirect exact from="/" to="login" />
        {userRoutes.map((route, idx) => (
            <UserMiddleware
              path={route.path}
              layout={UserLayout}
              component={route.component}
              key={idx}
              // user={user}
              profile={profile}
              exact
            />
          ))}

          {adminRoutes.map((route, idx) => (
            <AdminMiddleware
              path={route.path}
              layout={AdminLayout}
              component={route.component}
              key={idx}
              // user={user}
              profile={profile}
              exact
            />
          ))}
           {authRoutes.map((route, idx) => (
            <ForceRedirect
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
                auth={auth}
               profile={profile}
              // exact
            />
          ))}

           
           {noAccessRoutes.map((route, idx) => (
            <NoaccessMiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
                auth={auth}
               profile={profile}
              // exact
            />
          ))}
        </Switch>
      </Router>
      </SnackbarProvider>
    </>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
