import moment from 'moment'

export const initialState = {
    items: [
        {itemName: '', unitPrice: '', quantity: '', weight:'', length:'', width:'', height:''},
    ],
    total: 0,
    notes: '',
    rates: '',
    currency: '',
    invoiceNumber: Math.floor(Math.random() * (1000000)),
    status: '',
    type: 'Invoice',
    transportType: 'SEA',
    origin:'Murtala Muhammed Int. Airport',
    destination:'Murtala Muhammed Int. Airport',
    creator: '',
    createdAt: moment().format("MMM Do YYYY"),
}
