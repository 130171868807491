export const SET_USER = "SET_USER";
export const ERRORS = "ERRORS";
export const SET_PROFILE = "SET_PROFILE";
export const SET_PROFILES = "SET_PROFILES";
export const DELETE_PROFILE = "DELETE_PROFILE";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const PASSWORD_RESET_LINK_REQUEST = "PASSWORD_RESET_LINK_REQUEST";
export const PASSWORD_RESET_LINK_SUCCESS = "PASSWORD_RESET_LINK_SUCCESS";
export const PASSWORD_RESET_LINK_FAIL = "PASSWORD_RESET_LINK_FAIL";
export const PASSWORD_RESET_LINK_RESET = "PASSWORD_RESET_LINK_RESET";
export const RESET_PASSWORD_RESET = "RESET_PASSWORD_RESET";


export const USER_LOGOUT = "USER_LOGOUT";

export const AUTH = "AUTH"
export const CREATE_PROFILE = 'CREATE_PROFILE';
export const ADD_NEW_CLIENT = "ADD_NEW_CLIENT"