import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    padding: theme.spacing(2),
    border: 'solid 1px #bcbcbc',
    width: '100%'
    // backgroundColor: '#EEEEEE'
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),

    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'white',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#022b51',
    color:'#ffffff',
    '&:hover': {
      background: "#045cae",
   },
  },
}));
