import React from 'react'
import { Modal, Button,   ModalHeader,
  ModalBody, } from "reactstrap";

const DeleteConfirmation = ({ handleModalClose, openModal, Delete }) => {

    return (

      <Modal isOpen={openModal}>
      <ModalHeader>
      Delete Confirmation
      </ModalHeader >
      <ModalBody>
      <div className="alert">Are you sure you want to delete this</div>
      <Button variant="default" onClick={handleModalClose}>
            Cancel
          </Button>
          {/* <Button variant="danger" onClick={() => deleteConsignee( id) }> */}
          <Button style={{backgroundColor:'red', marginLeft:25}} onClick={Delete}>
            Delete
          </Button>
      </ModalBody>
      </Modal>
    )
}
 
export default DeleteConfirmation;