
import { ALL_FREIGHTS, ADD_NEW_FREIGHT, UPDATE_FREIGHT, DELETE_FREIGHT, FETCH_FREIGHTS_BY_USER, FETCH_FREIGHT, START_LOADING, END_LOADING } from './actionTypes'

const freightsReducer = (state = { isLoading: true, freights: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
      case ALL_FREIGHTS:
        return {
          ...state,
          freights: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
      case FETCH_FREIGHTS_BY_USER:
        return { ...state, freights: action.payload };

      case FETCH_FREIGHT:
        return { ...state, freight: action.payload.freight };
      case ADD_NEW_FREIGHT:
        return { ...state, freights: [...state.freights, action.payload] };
      case UPDATE_FREIGHT:
        return { ...state, freights: state.freights.map((freight) => (freight._id === action.payload._id ? action.payload : freight)) };
      case DELETE_FREIGHT:
        return { ...state, freights: state.freights.filter((freight) => freight._id !== action.payload) };
      default:
        return state;
    }
  };

  export default freightsReducer
