import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from "prop-types"
//import Empty from '../SvgIcons/NoData';
import {
  Button, Card, CardBody, Col, Container, Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

//import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min'
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import { useSnackbar } from 'react-simple-snackbar'

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { requestFreight, updateFreight, deleteFreight, getFreightsByUser} from '../../store/freights/actions'

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";


const UserFreights = () => {
  const dispatch = useDispatch();
  const location = useLocation()

  const [currentId, setCurrentId] = useState(null)
  const [openSnackbar, closeSnackbar] = useSnackbar()
  const singleFreight = useSelector((state)=> currentId ? state.freights.freights.find((c) => c._id === currentId) : null)
  const {freights} = useSelector((state) => state.freights);
  const [freightData, setFreightData] = useState({firstName:'', lastName:'', email: '', phone: '', address: '', userId: '', businessName:'', dimension:'', weight:'', location:''})
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
  const [modal, setModal] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)

  useEffect(() => {
    if(singleFreight) {
      setFreightData(singleFreight)
    }
  }, [singleFreight])

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('profile')))
    setFreightData({...freightData, userId: user?.result?._id})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location])


  useEffect(() => {
    var checkId = user?.result?._id
    if(checkId !== undefined) {
      setFreightData({...freightData, userId: [checkId]})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location])


  const handleSubmitFreight =(e)=> {
    e.preventDefault()
    if(currentId) {
      dispatch(updateFreight(currentId, freightData, openSnackbar, closeSnackbar))
    } else {
      dispatch(requestFreight(freightData, openSnackbar))
    }
     clear()
     handleClose()
}


const clear =() => {
  setCurrentId(null) 
  setFreightData({firstName:'', lastName:'', email: '', phone: '', businessName:'', dimension:'', weight:'', location:'', userId: [] })
}
  
const handleClose = () => {
  setModal(false);
};


const handleEditFreight = (selectedFreight) => {
  setModal((prevState) => !prevState)
  setCurrentId(selectedFreight)
}

useEffect(() => {
  dispatch(getFreightsByUser({ search: user?.result?._id}));
// eslint-disable-next-line react-hooks/exhaustive-deps
},[location, dispatch])


  const handleAddFreight = () => {
    if(user.result?.status==='Pending'){
      tog_backdrop()
    } else
    toggle()
  }


  const handleValidCustomerSubmit = () => {
    toggle()
  }


  
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }



  // if(freights.length === 0) {
  //   return  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px'}}>
  //     <Empty />
  //   <p style={{padding: '40px', color: 'gray'}}>Nothing to display</p>
  //   </div>
  // }

  //pagination customization

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing { from } to { to } of { size } Results
    </span>
  );

  const options  = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
      text: '10', value: 10
    }, {
      text: '15', value: 15
    }, {
      text: 'All', value: freights.length
    }]
  };


  const FreightColumns = [
    {
      text: "First Name",
      dataField: "firstName",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{row.firstName}</p>
          
        </>
      ),
    },
    {
      text: "Last Name",
      dataField: "lastName",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{row.lastName}</p>
          
        </>
      ),
    },
    {
      text: "Phone Number",
      dataField: "phone",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{row.phone}</p>
          
        </>
      ),
    },
   
    {
      text: "Email Address",
      dataField: "email",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p  className="mb-0">{row.email}</p>
        </>
      ),
    },
    {
      text: "Location",
      dataField: "location",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-0">{row.location}</p>
        </>
      ),
    },
    {
      text: "Business Name",
      dataField: "businessName",
      sort: true,
  
      formatter: (cellContent, row) => (
        <>
          <p className="mb-0">{row.businessName}</p>
        </>
      ),
    },
    {
      text: "Dimension",
      dataField: "dimension",
      sort: true,
  
      formatter: (cellContent, row) => (
        <>
          <p className="mb-0">{row.dimension}</p>
        </>
      ),
    },
    {
      text: "Weight",
      dataField: "weight",
      sort: true,
  
      formatter: (cellContent, row) => (
        <>
          <p className="mb-0">{row.weight}</p>
        </>
      ),
    },


    {
      text: "Actions",
      dataField: "_idnothinjgjg",
      formatter: (cellContent, freight) => (
        <>
         <UncontrolledDropdown direction="left">
          <DropdownToggle className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem onClick={() => handleEditFreight(freight._id)}>
              <i className="bx bxs-edit-alt text-success me-1" />
              Edit
            </DropdownItem>
            <DropdownItem onClick={() => dispatch(deleteFreight(freight._id))}>
              <i className="fas fa-trash-alt text-danger me-1" />
              Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        </>
      ),
    },
  ]

  const toggle = () => {
    setModal(!modal)
  }

  
  const { SearchBar } = Search

  return (
    <>
    <div className="page-content">
        <MetaTags>
          <title>Amgray Logistics Freights</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Freights" breadcrumbItem="Freights" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(options)}
                    keyField='_id'
                    columns={FreightColumns}
                    data={freights}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={freights || []}
                        columns={FreightColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                   style={{background:"#022b51"}}
                                    className="btn mb-2 me-2 app-button"
                                    onClick={handleAddFreight}
                                  >
                                    Request Freight
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                               
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    
                                    // defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                     keyField="_id"
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                  />
                                </div>

          {/* User Approval Modal Start  */}
                     <Modal
                      isOpen={modal_backdrop}
                      toggle={() => {
                        tog_backdrop()
                      }}
                      scrollable={true}
                      id="staticBackdrop"
                    >
                      <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Alert</h5>
                        <button type="button" className="btn-close"
                          onClick={() => {
                            setmodal_backdrop(false)
                          }} aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                        <p style={{color:'red', fontSize:15}}>Sorry, we are currently reviewing your credentials</p>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-light" onClick={() => {
                          setmodal_backdrop(false)
                        }}>Close</button>
                      </div>
                    </Modal>
                     {/* User Approval Modal End  */}
                                <Modal
                                  isOpen={modal}
                                  toggle={toggle}
                                >
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {currentId? 'Edit Freight' : 'Request Freight'}
                                  </ModalHeader>
                                  <ModalBody>
                                    <AvForm
                                      onValidSubmit={
                                        handleValidCustomerSubmit
                                      }
                                    >
                                      <Row form>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <AvField
                                              name="firstName"
                                              label="First Name"
                                              type="text"
                                              onChange={(e) => setFreightData({...freightData, firstName: e.target.value})}
                                              value={freightData.firstName} 
                                            
                                            />
                                          </div>

                                          <div className="mb-3">
                                            <AvField
                                              name="lastName"
                                              label="Last Name"
                                              type="text"
                                              onChange={(e) => setFreightData({...freightData, lastName: e.target.value})}
                                              value={freightData.lastName} 
                                            
                                            />
                                          </div>

                                          <div className="mb-3">
                                            <AvField
                                              name="phone"
                                              label="Phone Number"
                                              type="text"
                                              onChange={(e) => setFreightData({...freightData, phone: e.target.value})}
                                              value={freightData.phone} 
                                             
                                            />
                                          </div>

                                          <div className="mb-3">
                                            <AvField
                                              name="email"
                                              label="Email Address"
                                              type="email"
                                              onChange={(e) => setFreightData({...freightData, email: e.target.value})}
                                              value={freightData.email} 
                                              
                                            />
                                          </div>

                                          <div className="mb-3">
                                            <AvField
                                              name="location"
                                              label="Location"
                                              type="textarea"
                                              rows="3"
                                              onChange={(e) => setFreightData({...freightData, location: e.target.value})}
                                              value={freightData.location} 
                                            
                                            />
                                          </div>


                                          <div className="mb-3">
                                            <AvField
                                              name="businessName"
                                              label="Business Name"
                                              type="text"
                                              errorMessage="Invalid Business name"
                                              onChange={(e) => setFreightData({...freightData, businessName: e.target.value})}
                                              value={freightData.businessName} 
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="dimension"
                                              label="Dimension"
                                              type="text"
                                              errorMessage="Invalid dimension"
                                              onChange={(e) => setFreightData({...freightData, dimension: e.target.value})}
                                              value={freightData.dimension} 
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="weight"
                                              label="Weight in Kg"
                                              type="number"
                                              placeholder="Total gross weight (Kg)"
                                              errorMessage="Invalid weight"
                                              onChange={(e) => setFreightData({...freightData, weight: e.target.value})}
                                              value={freightData.weight} 
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button onClick={handleSubmitFreight}
                                            style={{background:"#022b51", color:"#fff"}}
                                              type="submit"
                                              className="btn save-customer"
                                            >
                                              Submit
                                            </button> 

                                          </div>
                                        </Col>
                                      </Row>
                                    </AvForm>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

UserFreights.propTypes = {
  freights: PropTypes.array,
 
}
export default UserFreights;