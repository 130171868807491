import React, {useEffect} from 'react'
import { Container, Row, Col } from "reactstrap"
import MetaTags from 'react-meta-tags';
import { toCommas } from '../../../utils/commas'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Empty from '../../SvgIcons/NoData';
import Spinner from '../../../components/Spinner/Spinner';

//Import Breadcrumb
import Breadcrumbs from "../../../components/common/Breadcrumb"
//Import Components
 import PaymentWidget from "./PaymentWidget";
 import RecentPayments from './RecentPayments'

 // Redux Actions
 import { getInvoices } from '../../../store/invoices/actions';
 import {getFreights} from '../../../store/freights/actions';

const Payments = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { invoices, isLoading } = useSelector((state) => state?.invoices)

  let paymentHistory = []
  for(let i = 0; i < invoices.length; i++) {
      let history = []
      if(invoices[i].paymentRecords !== undefined) {
          history = [...paymentHistory, invoices[i].paymentRecords]
          paymentHistory = [].concat.apply([], history);
      }
      
  }

      //sort payment history by date
      const sortHistoryByDate =  paymentHistory.sort(function(a, b) {
        var c = new Date(a.datePaid);
        var d = new Date(b.datePaid);
        return d-c;
    });

  // Total Paid
  let totalPaid = 0
  for(let i = 0; i < invoices.length; i++) {
      if(invoices[i].totalAmountReceived !== undefined) {
          totalPaid += invoices[i].totalAmountReceived
      }
      
  }

  // Total Amount
  let totalAmount = 0
  for(let i = 0; i < invoices.length; i++) {
      totalAmount += invoices[i].total
  }

   
  useEffect(() => {
    dispatch(getInvoices());
    // eslint-disable-next-line
}, [location, dispatch]);

// Freights
useEffect(() => {
  dispatch(getFreights());
// eslint-disable-next-line react-hooks/exhaustive-deps
},[location, dispatch])


if(isLoading) {
  return  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px'}}>
      <Spinner />
  </div>
}

if(invoices.length === 0) {
  return  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px'}}>
    <Empty />
  <p style={{padding: '40px', color: 'gray'}}>Nothing to display</p>
  </div>
}
    const dashboardData = {
      reports: [
          {
            icon: "bx bx-check-circle",
            title: "Payment Received",
            value: `$ ${toCommas((totalPaid).toFixed(2))}`,
          },
          {
            icon: "bx bx-money",
            title: "Total Amount",
            value: `$ ${toCommas((totalAmount).toFixed(2))}`,
          },
          {
            icon: "bx bxs-report",
            title: "Pending Amount",
            value: `$ ${toCommas((totalAmount - totalPaid).toFixed(2))} `,
          },
         
        ]
  };
    return (
        <>
        <div className="page-content">
          <MetaTags>
            <title>Payments</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Payments" breadcrumbItem="Payments" />

            <Row>
              <Col xl="12">
                <Row>
                  {/*mimi widgets */}
                  <PaymentWidget reports={dashboardData.reports} />
                </Row>
              </Col>
            </Row>
            <Row>
              <RecentPayments paymentHistory={sortHistoryByDate} />
            </Row>
          </Container>
        </div>
      </>
    );
}

export default Payments;










