
const trackColumns = () => [
    {
      dataField: "id",
      text: "#",
    },
    {
      dataField: "localtime",
      text: "Local time",
    },
    {
      dataField: "date",
      text: "Dates",
   
    },
    {
    dataField: "activity",
    text: "Activities",

      },
  ]
  
  export default trackColumns
  