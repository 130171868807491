import React from 'react'
import MetaTags from 'react-meta-tags';
import styles from './Settings.module.css'
import Form from './Form/Form'

//Import Breadcrumb
import Breadcrumbs from "../../../components/common/Breadcrumb";
const AdminSettings = () => {

    // const history = useHistory()
    const user = JSON.parse(localStorage.getItem('profile'))
    
    return (
        <>
        <div className="page-content">
        <MetaTags>
          <title>Settings</title>
          </MetaTags>
        <Breadcrumbs title="Settings" breadcrumbItem="settings" />
        <div className={styles.pageContainer}>
            <Form user={user} />
       
    </div>
    </div>
    </>
    )
}

export default AdminSettings
