import React, {useState} from "react"
import { Row, Col, CardBody, Card, Container, Form, Label, Input } from "reactstrap"
import { useDispatch } from 'react-redux';
//import styles from './Password.module.css'
//import Loader from './Loader';

import { Link } from "react-router-dom"
// import images
import Logo from "../../assets/images/amgray-logo-dark.png"

import { forgotPassword } from '../../store/auth/actions';

const ForgotPassword = () => {
  const [form, setForm] = useState("");
  const [step, setStep] = useState(0)
  const dispatch = useDispatch();
    //const errors = useSelector(state=>state.errors)

 const handleSubmit = (e) => {
  e.preventDefault()
  dispatch(forgotPassword({email: form}))
  window.navigator.onLine ? setStep(1) : setStep(2)
}

const handleChange = (e) => setForm(e.target.value);

  return (
    <>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
        <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft">
                  <Row>
                  <Col className="align-self-end">
                      <img src={Logo} alt="Amgray logo" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-5">
                {step === 0 && (

               
                  <div className="p-2">
                  <div className="alert alert-success text-center mb-4" role="alert">
                    Enter your Email and instructions will be sent to you!
                    </div>
                    <Form>
                    <Row>
                    <Col md={12}>
                    {/* {<span style={{color:"red"}}>{errors.email}</span>} */}
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Email</Label>
                          <Input
                            required
                            type="email"
                            className="form-control"
                            onChange={handleChange}
                          />
                        </div>
                        </Col>
                    </Row>
                      <Row className="form-group mt-1">
                      <div className="mt-4 d-grid">
                        <button
                        onClick={handleSubmit}
                          className="btn btn-block"
                          type="submit"
                          style={{background:"#022b51", color:"#fff", paddingTop:'0.85rem'}}
                        >
                          Reset Password
                        </button>
                      </div>
                      
                        
                      </Row>
                      </Form>
                  </div>
                     )}
                  {step === 1 && (
                  <div className="p-2">
                  <div className="text-center">
                    <div className="avatar-md mx-auto">
                      <div className="avatar-title rounded-circle bg-light">
                        <i   style={{color:"#022b51"}} className="bx bxs-envelope h1 mb-0"></i>
                      </div>
                    </div>
                    <div className="p-2 mt-4">
                      <h4>Verify your email</h4>
                      <p className="alert-success">
                        A password reset link has been sent to your email. Please follow the link to reset your passwordl{" "}
                        </p>
                      <div className="mt-4">
                        <button
                        onClick={()=>setStep(0)}
                          href="/"
                          style={{background:"#022b51", color:"#fff"}}
                          className="btn w-md"
                        >
                          Resend Link
                          </button>
                      </div>
                    </div>
                  </div>
                </div>
                )}
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link
                    to="/"
                    style={{color:"#022b51", fontWeight:"bold"}}
                
                  >
                    Login
                  </Link>{" "}
                </p>
                
                <p>
                  © {new Date().getFullYear()} Amgray Logistics
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ForgotPassword
