import {axiosInstance} from './config';

axiosInstance.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')) {
      req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`
  }

  return req
})

// Profile
export const fetchProfilesBySearch = (searchQuery) => axiosInstance.get(`/profiles/search?searchQuery=${searchQuery.search || searchQuery.year || 'none'}`);
export const fetchProfile = (id) => axiosInstance.get(`/profiles/${id}`)
export const fetchProfiles = () => axiosInstance.get('/profiles');
export const fetchProfilesByUser = (searchQuery) => axiosInstance.get(`/profiles?searchQuery=${searchQuery.search}`)
export const createProfile = (newProfile) => axiosInstance.post('/profiles', newProfile);
export const updateProfile = (id, updatedProfile) => axiosInstance.patch(`/profiles/${id}`, updatedProfile);
export const deleteProfile = (id) => axiosInstance.delete(`/profiles/${id}`);

export const register = (formData) => axiosInstance.post('/users/register', formData);
export const signIn = (formData) => axiosInstance.post('/users/login', formData);
export const forgotPassword = (formData) => axiosInstance.post('/users/forgot', formData);
export const resetPassword = (formData) => axiosInstance.post('/users/reset', formData);


// Users
export const fetchUser = (id) => axiosInstance.get(`/users/${id}`);
export const fetchUsers = (page) => axiosInstance.get(`/users`);
export const updateUser = (id, updatedUser) => axiosInstance.patch(`/users/${id}`, updatedUser)
export const deleteUser =(id) => axiosInstance.delete(`/users/${id}`)
export const fetchUsersByUser = (searchQuery) => axiosInstance.get(`/users/user?searchQuery=${searchQuery.search}`);
export const approveUser = (id, approvedUser) => axiosInstance.patch(`/users/${id}`, approvedUser)


// Clients
export const fetchClient = (id) => axiosInstance.get(`/clients/${id}`);
export const fetchClients = () => axiosInstance.get(`/clients`);
export const addClient =( client ) => axiosInstance.post('/clients', client)
export const updateClient = (id, updatedClient) => axiosInstance.patch(`/clients/${id}`, updatedClient)
export const deleteClient =(id) => axiosInstance.delete(`/clients/${id}`)
export const fetchClientsByUser = (searchQuery) => axiosInstance.get(`/clients/user?searchQuery=${searchQuery.search}`);


// Freights
export const fetchFreight = (id) => axiosInstance.get(`/freights/${id}`);
export const fetchFreights = () => axiosInstance.get(`/freights`);
export const addFreight =( freight ) => axiosInstance.post('/freights', freight)
export const updateFreight = (id, updatedFreight) => axiosInstance.patch(`/freights/${id}`, updatedFreight)
export const deleteFreight =(id) => axiosInstance.delete(`/freights/${id}`)
export const fetchFreightsByUser = (searchQuery) => axiosInstance.get(`/freights/user?searchQuery=${searchQuery.search}`);

// Invoices   
export const fetchInvoice =(id) => axiosInstance.get(`/invoices/${id}`)
export const fetchInvoices = () => axiosInstance.get(`/invoices`);
export const addInvoice =( invoice ) => axiosInstance.post('/invoices', invoice)
export const updateInvoice = (id, updatedInvoice) => axiosInstance.patch(`/invoices/${id}`, updatedInvoice)
export const deleteInvoice =(id) => axiosInstance.delete(`/invoices/${id}`)
export const fetchInvoicesByUser = (searchQuery) => axiosInstance.get(`/invoices?searchQuery=${searchQuery.search}`);

// Consignees
export const fetchConsignee = (id) => axiosInstance.get(`/consignees/${id}`);
export const fetchConsignees = () => axiosInstance.get(`/consignees`);
export const addConsignee =( consignee ) => axiosInstance.post('/consignees', consignee)
export const updateConsignee = (id, updatedConsignee) => axiosInstance.patch(`/consignees/${id}`, updatedConsignee)
export const deleteConsignee =(id) => axiosInstance.delete(`/consignees/${id}`)
export const fetchConsigneesByUser = (searchQuery) => axiosInstance.get(`/consignees/user?searchQuery=${searchQuery.search}`);
