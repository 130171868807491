import * as api from '../../api/index'
import {ALL_CONSIGNEES, UPDATE_CONSIGNEE,DELETE_CONSIGNEE, ADD_NEW_CONSIGNEE, FETCH_CONSIGNEES_BY_USER, FETCH_CONSIGNEE, START_LOADING, END_LOADING } from './actionTypes'


export const getConsignee = (id) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.fetchConsignee(id);
      dispatch({ type: FETCH_CONSIGNEE, payload: { consignee: data } });

    } catch (error) {
      console.log(error);
    }
  };

  export const getConsignees = () => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING })
      const { data } = await api.fetchConsignees();
      dispatch({ type: ALL_CONSIGNEES, payload: data });
      dispatch({ type: END_LOADING })
  
    } catch (error) {
      console.log(error);
    } };


export const getConsigneesByUser =(searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING })

     const  { data: { data } } = await api.fetchConsigneesByUser(searchQuery)
      dispatch({ type: FETCH_CONSIGNEES_BY_USER, payload: data });
      dispatch({ type: END_LOADING })
    } catch (error) {
      
    }
  }


export const createConsignee =(consigneeData, openSnackbar, closeSnackbar) => async (dispatch) => {
    try {
        const { data } = await api.addConsignee(consigneeData)
        dispatch({ type: ADD_NEW_CONSIGNEE, payload: data })
         openSnackbar("Consignee added successfully")
    } catch (error) {
        console.log(error)
    }
}


export const updateConsignee =(currentId, consigneeData, openSnackbar, closeSnackbar) => async (dispatch) => {
    try {
    const { data } = await api.updateConsignee(currentId, consigneeData)
    dispatch({ type: UPDATE_CONSIGNEE, payload: data })
     openSnackbar("Consignee updated successfully")
  
    } catch (error) {
        console.log(error)
    }
}

export const deleteConsignee =(id, openSnackbar) => async (dispatch) => {
    try {
        await api.deleteConsignee(id)

        dispatch({type: DELETE_CONSIGNEE, payload: id})
         openSnackbar("Consignee deleted successfully")
    } catch (error) {
        console.log(error)
    }
}