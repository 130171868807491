import React, {useState} from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap"
import { useSelector } from 'react-redux'
import ProgressButton from 'react-progress-button'
import { useSnackbar } from 'react-simple-snackbar'
import styles from  './InvoiceDetails.module.css'
import PaymentHistory from './PaymentHistory';
// import InvoiceLabel from "./InvoiceLabel"

import Modal from '../../../../components/Payments/Modal';
import LabelModal from '../../../../components/Payments/LabelModal';

const Invoicedetailsheader = ({sendPdf, sendStatus, invoiceData, editInvoice}) => {
  const { invoice } = useSelector((state) => state.invoices)
    // eslint-disable-next-line
    const [openSnackbar, closeSnackbar] = useSnackbar()
    const [open, setOpen ] = useState(false)
    const [openLabel, setOpenLabel ] = useState(false)

     //Print the Invoice
  const printInvoice = () => {
    window.print()
  }

    return (
        <>        
        <Modal open={open} setOpen={setOpen} invoice={invoice} />
        <LabelModal open={openLabel} setOpen={setOpenLabel} invoice={invoice} />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <Row>
                  <Col lg="6" className="align-self-center">
                    <div className="text-lg-center mt-4 mt-lg-0">
                      <Row>
               <Col xs="4">
               <ProgressButton className={styles.printPageButton}
                      onClick={sendPdf}
                      state={sendStatus}
                      onError={() => closeSnackbar("Invoice Not Sent")}
                      onSuccess={() => openSnackbar("Invoice sent successfully")}
            >
              Send to Client
            </ProgressButton>
                </Col>
                <Col>
                    <div className={styles.printPageButton}>
                      <p style={{background:"#022b51", color:"#fff", marginLeft:"auto", borderRadius:4, cursor:'pointer', padding:" 10px 10px"}} onClick={() => editInvoice(invoiceData._id)} className="text-truncate mb-2">
                      Edit Invoice
                      </p>    
                    </div>
                        </Col>
                        <Col xs="4">
                          <div className={styles.printPageButton}>
                            <p style={{background:"#022b51", color:"#fff", marginLeft:"auto", borderRadius:4, cursor:'pointer', padding:" 10px 1px"}}   onClick={() => setOpen((prev) => !prev)} className="text-truncate mb-2">
                              Record Payment
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col lg="6" style={{display:'flex'}}>
                      {/* Print Label */}
                      <button   className={styles.printPageButton}   onClick={() => setOpenLabel((prev) => !prev)} style={{background:"#022b51", color:"#fff", marginLeft:"auto", borderRadius:4}}>Preview Label</button>
                      <button
                        className={styles.printPageButton}
                        onClick={printInvoice} 
                        style={{background:"#022b51", color:"#fff", marginLeft:"auto", borderRadius:4}}
                         > <i className="fa fa-print" /> Print Invoice</button>    
                  </Col>
                 
                </Row>
              </CardBody>
            </Card>
            {invoice?.paymentRecords.length !== 0 && (
          <PaymentHistory paymentRecords={invoiceData?.paymentRecords} setOpen={setOpen} />
        )}

          </Col>
        </Row>
      </>
    );
}
export default Invoicedetailsheader;
