import React from "react"
import MetaTags from 'react-meta-tags';
//import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Table, Label} from "reactstrap"
import { isEmpty, map } from "lodash"

import { useSelector } from 'react-redux';
//Import Breadcrumb
import Breadcrumbs from "../../../components/common/Breadcrumb"

//Import Image
import logo from "../../../assets/images/amgray-logo.png"
import PropTypes from "prop-types"
// import { getInvoices } from "../../../store/invoices/actions"

const InvoiceDetail = () => {

    const invoiceDetail = useSelector((state) => state.invoices.invoices);
   // const dispatch = useDispatch();


    const data = [
      {id:1, description:"Delivery of a 4.5KVA Mikano generator", quantity:2, price:"200,000"},
      {id:2, description:"Delivery of 12kg package to USA",  quantity:1, price:"175,000"},
      {id:3, description:"Delivery of parcel",  quantity:12, price:"25,000"},
    ]


    // const customerInfo = [
    //   {name:"Onimisi", email:"onimisi@gmail.com", PhoneNo:"0803912345", address:"Ikeja Lagos"}
    // ]
 
    
    const shippingInfo = [
      {name:"Onimisi", orderDate:"April 18, 2022", dueDate:"April 20, 2022", amount:"400,000"}
    ]

    // useEffect(() => {
    //     dispatch(getInvoices()); 
    //   }, [dispatch])

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Invoice Detail | Amgray Logistics</title>
        </MetaTags>
        <Container fluid>
      
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Invoice" breadcrumbItem="Invoice Detail" />
          <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <Row>
                  <Col style={{display:"flex"}}>
                      <button style={{background:"#022b51", color:"#fff", marginLeft:"auto"}} className="btn app-button">Download</button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          </Row>
         
          {!isEmpty(invoiceDetail) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="invoice-title">
                      <h4 className="float-end font-size-16">
                        InvoiceId # 2031234{invoiceDetail.id}
                      </h4>
                      <div className="mb-4">
                        <img src={logo} alt="logo" height="39" />
                      </div>
                    </div>
                    <hr />
                    <Row>
                      <Col xs="6">
                        <address>
                          <strong><div className="mb-4">
                          </div>From:</strong>
                          <br />
                          <h6>Amgray Logistics Limited</h6>
                          <br />
                          {/* {map(
                            customerInfo,
                            (item, key) => (
                              <React.Fragment key={key}>
                              <h6>Name:</h6>
                              <span>{item.name} </span>
                                <br />
                                <h6>Email Address:</h6>
                                <span>{item.email} </span>
                                <br />
                                <h6>Phone Number:</h6>
                                <span>{item.PhoneNo} </span>
                                <br />
                                <h6>Address:</h6>
                                <span>{item.address} </span>
                              </React.Fragment>
                            )
                          )} */}
                        </address>
                        <strong><div className="mb-4">
                          </div>Bill To:</strong>
                          <h6>Onimisi Kunle</h6>
                          <br />
                      </Col>
                      <Col xs="6" className="text-end">
                        <address>
                          <strong>Balance Due:</strong>
                          <br />
                          {shippingInfo.orderDate}
                            <span>$ 400,000</span>
                        </address>

                      <Col xs="12" className="text-end">
                        <address>
                          <strong>Invoice Date:</strong>
                          <br />
                          {shippingInfo.orderDate}
                            <span>March 14, 2022</span>
                          <br />
                        </address>
                      </Col>
                      <Col xs="12" className="text-end">
                        <address>
                          <strong>Due Date:</strong>
                          <br />
                          {shippingInfo.orderDate}
                            <span>May 29, 2022</span>
                          <br />
                          <br />
                        </address>
                      </Col>
                      </Col>
                    </Row>
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 fw-bold">
                        Order summary
                      </h3>
                    </div>
                    <div className="table-responsive">
                      <Table className="table-nowrap">
                        <thead>
                          <tr style={{background:"#022b51", color:"#fff"}}>
                            <th style={{ width: "70px" }}>No.</th>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th className="text-end">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {map(
                            data,
                            (item, key) => (
                              <tr key={key}>
                                <td>{item.id}</td>
                                <td>{item.description}</td>
                                <td>{item.quantity}</td>
                                <td className="text-end">$ {item.price}</td>
                              </tr>
                            )
                          )}
                          <tr>
                            <td colSpan="2" className="text-end">
                              Sub Total
                            </td>
                            <td className="text-end">
                              {invoiceDetail.amount}
                              $ 400,000
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" className="border-0 text-end">
                              <strong>Shipping</strong>
                            </td>
                            <td className="border-0 text-end">
                              {invoiceDetail.shippingAddress}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" className="border-0 text-end">
                              <strong>Total</strong>
                            </td>
                            <td style={{background:"#f5f4f3"}} className="border-0 text-end">
                              <h5 className="m-0">
                                {invoiceDetail.amount}
                                $ 175,000
                              </h5>
                            </td>
                      
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div className="mb-5 col-lg-6">
                          <Label htmlFor="formmessage">Notes</Label>
                          <p>Thank you for you for your patronage</p>
                        </div>
                  </CardBody>
                  
                </Card>
              </Col>
             
            </Row>
          )}
        </Container>
      </div>
    </>
  )
}

InvoiceDetail.propTypes = {
  invoiceDetail: PropTypes.object,
}

export default  InvoiceDetail;