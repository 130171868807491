import React, { useState } from "react"
import PropTypes from "prop-types"
// import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {PaginationProvider} from "react-bootstrap-table2-paginator"
//import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min'
import * as moment from 'moment';
import { Card, CardBody, Col, Container, Row,} from "reactstrap"

const RecentPayments = ({paymentHistory}) => {
  const [modal, setModal] = useState(false)

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing { from } to { to } of { size } Results
    </span>
  );
  
   //pagination customization
  const options  = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
      text: '10', value: 10
    }, {
      text: '15', value: 15
    }, {
      text: 'All', value: paymentHistory.length
    }]
  };

  const InvoiceColumns = () => [
    // {
    //   dataField: "paidBy",
    //   text: "Paid By",
    //   sort: true,
    //   formatter: (cellContent, row) => (
    //     <p>{row?.paidBy}</p>
    //     )
    // },

    {
        dataField: "datePaid",
        text: "Date Paid",
        sort: true,
        formatter: (cellContent, row) => (
          handleValidDate(row?.datePaid)
        ),
      },
      {
        dataField: "amountPaid",
        text: "Amount Paid",
        sort: true,
        formatter: (cellContent, row) => (
        <p style={{color: '#00A86B', fontSize: '14px'}}>$ {row?.amountPaid}</p>
        ),
      },
    {
      dataField: "paymentMethod",
      text: "Payment Method",
    },
    {
        dataField: "note",
        text: "Notes",
      },
  ]

  const toggle = () => {
    setModal(!modal)
  }

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(options)}
                    keyField='_id'
                    columns={InvoiceColumns(toggle)}
                    data={paymentHistory}
                  >
                    {({paginationTableProps}) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={paymentHistory}
                        columns={InvoiceColumns(toggle)}                     
                      >
                        {toolkitProps => (
                          <>
                            <Row className="mb-2">
                            <h4 style={{textAlign: 'center', padding: '30px' }}>{paymentHistory.length ? 'Recent Payments' : 'No payment received yet'}</h4>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                               
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

RecentPayments.propTypes = {
    getInvoices: PropTypes.func,
 
}
export default RecentPayments