import React from 'react';
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Label,
  Button,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/common/Breadcrumb"

import {Link} from "react-router-dom";

  const Trackfreight = () => {
      return (
        <>
        <div className="page-content">
        <MetaTags>
          <title>Track Freight</title>
        </MetaTags>
          <Container fluid>
          <Breadcrumbs title="Track Freight" breadcrumbItem="Track Freight" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Track Order</h4>
                          <form>
                            <div className="mb-3">
                                <p>Enter the order number you would like to track in the form below.</p>
                              <Label>Track an Order :</Label>
                              <Input type="text" className="form-control" placeholder="Enter Tracking Number" />
                            </div>
                            <div className="text-center mt-4">
                                <Link to="/user/dashboard/tracking-details">
                              <Button className='app-button' style={{background:"#022b51", padding:"10px 45px"}} type="button">
                              Track Order
                              </Button>
                              </Link>
                            </div>
                          </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
      );
  } 
  export default Trackfreight;