import PropTypes from 'prop-types'
import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Media } from "reactstrap"

const PaymentDetails = props => {
    return (
        <>
        <Col xl="" md="6">
          <Card className="plan-box">
            <CardBody className="p-4">
              <Media>
                <Media body>
                  <h5>{props.pricing.title}</h5>
                  <p className="text-muted">{props.pricing.description}</p>
                </Media>
              </Media>
              <div className="py-4">
                <h2>
                  <span className="font-size-13">{props.pricing.duration}</span>
                </h2>
              </div>
              <div className="text-center">
                <Link
                  to='#'
                  style={{background:"#022b51", color:"#fff"}}
                  className="btn"
                >
                  Pay Now
                </Link>
              </div>
            </CardBody>
          </Card>
        </Col>
      </>
    );
}

PaymentDetails.propTypes = {
    pricing: PropTypes.object
  }
export default PaymentDetails;
