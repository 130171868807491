import {AUTH, USER_LOGOUT
} from "../auth/actionTypes";

const initialState = {
  authData: null
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
      case AUTH:
            localStorage.setItem('profile', JSON.stringify({...action?.data}))
            return {...state, authData: action?.data}
      
      case USER_LOGOUT:
        return {};
    default:
      return state;
  }
}