import React from 'react';
import { Container, Row, Col } from "reactstrap"
import MetaTags from 'react-meta-tags';
import PaymentDetails from './PaymentDetails';

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb"
const UsersPayment = () => {
    const pricings = [
        {
          id: 1,
          title: "Cash App",
          description: "your safe way to make payment",
          duration: "Account Details",
          link: "",
         
        },
        {
          id: 2,
          title: "Mobile Payment",
          description: "Guarantee Trust Bank",
          duration: "Payment Details",
          link: "",
         
        },
        {
          id: 3,
          title: "Bank Transfer",
          description: "your safe way to make payment",
          duration: "Payment Details",
          link: "",
          
        },
      ]

    return (
        <>
        <div className="page-content">
          <MetaTags>
            <title>Payments</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Payments" breadcrumbItem="Payments" />
  
            <Row className="justify-content-center">
              <Col lg={6}>
                <div className="text-center mb-5">
                  <h4>Payment</h4>
                  <p className="text-muted">
                    You can use any of these payment details to make your payment
                  </p>
                </div>
              </Col>
            </Row>
  
            <Row>
              {pricings.map((pricing, key) => (
                <PaymentDetails pricing={pricing} key={"_pricing_" + key} />
              ))}
            </Row>
          </Container>
        </div>
      </>
    );
}

export default UsersPayment;
