import { combineReducers } from "redux"
// Front
import Layout from "./layout/layoutReducer";

// Reducers
import usersReducer from './users/reducer'
import clientsReducer from './clients/reducer'
import consigneesReducer from './consignees/reducer'
import freightsReducer from './freights/reducer'
import invoiceReducer from './invoices/reducer'
import errorReducer from './errors/reducer';
import { authReducer} from './auth/reducer'
import profileReducer from './profile/reducer'

const rootReducer = combineReducers({
    // public
    Layout,
   
    freights:freightsReducer,
    invoices:invoiceReducer,
    clients: clientsReducer,
    consignees: consigneesReducer,        
    users: usersReducer,
    auth:authReducer,
    errors:errorReducer,
    profiles:profileReducer
  })
  
  export default rootReducer;