import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from "prop-types"
import {  Link } from "react-router-dom"
import * as moment from 'moment';
// import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import { useDispatch, useSelector } from 'react-redux';
import paginationFactory, { PaginationProvider} from "react-bootstrap-table2-paginator"
//import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min'

import { Card, CardBody, Col, Container, Row, Badge } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/common/Breadcrumb";
import Empty from '../../SvgIcons/NoData';
//import Spinner from '../../../components/Spinner/Spinner';

import { useLocation } from 'react-router-dom'
import { getInvoice} from '../../../store/invoices/actions';


const Invoices = () => {
  const user = useState(JSON.parse(localStorage.getItem('profile')))

  const {invoices} = useSelector((state) => state.invoices);

const dispatch = useDispatch();
const location = useLocation()


useEffect(() => {
  dispatch(getInvoice(user?.result?._id));
// eslint-disable-next-line react-hooks/exhaustive-deps
},[location, dispatch])


// console.log("Invoices", invoices)

  // if(isLoading) {
  //   return  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px'}}>
  //       <Spinner />
  //   </div>
  // }
  
  if(invoices.length === 0) {
    return  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px', marginTop:"75px"}}>
      <Empty />
    <p style={{padding: '40px', color: 'gray'}}>No invoice to display</p>
    </div>
  }

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing { from } to { to } of { size } Results
    </span>
  );

  const options  = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
      text: '10', value: 10
    }, {
      text: '15', value: 15
    }, {
      text: 'All', value: invoices.length
    }]
  };

  const { SearchBar } = Search

  const InvoiceColumns = () => [

    {
      dataField: "id",
      text: "Invoice #",
      sort: true,
      formatter: (cellContent, row) => (
        <Link to='/user/dashboard/invoice-details' className="text-body fw-bold">
           {/* <Link to={`/invoice-details/${row.id}`} className="text-body fw-bold"></Link> */}
          {row.id}
        </Link>
      ),
    },
    {
      dataField: "dueDate",
      text: "Date",
      sort: true,
      formatter: (cellContent, row) => (
        handleValidDate(row.dueDate)
      ),
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
    },
    {
      dataField: "status",
      text: "Payment Status",
      sort: true,
    },
    {
      dataField: "stx",
      text: "",
      formatter: (cellContent, row) => (
        <Badge
          className={"font-size-12 badge-soft-" + row.badgeclass}
          color={row.badgeClass}
          pill
        >
        </Badge>
      ),
    },

    {
      dataField: "any",
      text: "View Details",
      sort: false,
      formatter: (cellContent, row) => (
        // <Link to={`/invoice-details/${row.id}`}   type="button"  color="primary" className="btn-sm btn-rounded">
          <Link to='/user/dashboard/invoice-details' style={{background:"#022b51", color:"white"}}  type="button" className="btn-sm btn-rounded app-button">
          View Details
        </Link>
      ),
    },
  ]



  // const toggle = () => {
  //   setModal(!modal)
  // }

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  }

  const defaultSorted = [{
    dataField: 'orderId',
    order: 'desc'
  }];

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Invoices</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Invoices" breadcrumbItem="Invoices" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(options)}
                    keyField='id'
                    columns={InvoiceColumns()}
                    data={invoices}
                  >
                    {({ paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={invoices}
                        columns={InvoiceColumns()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                             
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="_id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                               
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

Invoices.propTypes = {
    getInvoices: PropTypes.func,
 
}
export default Invoices;