import React from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"
import MetaTags from 'react-meta-tags';
//Import Images
import error from "../assets/images/404 Error.gif"

const  NotFound = () => {
  const user = JSON.parse(localStorage.getItem('profile'))
  return (
    <>
      <div className="account-pages my-5 pt-5">
        <MetaTags>
          <title>404 Error Page</title>
        </MetaTags>
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h1 className="display-2 font-weight-medium">
                  4<i style={{color:"#022b51"}} className="bx bx-buoy bx-spin display-3" />
                  4
                </h1>
                <h4 style={{color:"#022b51"}} className="text-uppercase">Sorry, page not found</h4>
                <div className="mt-5 text-center">
                  <Link to={user.result.role ==="USER"? "/user/dashboard": "/admin/dashboard"}
                     
                    className="btn app-button"
                    style={{background:"#022b51", color:"#fff"}}
                    
                    // to="/"
                  >
                    Back to Dashboard
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src={error} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default NotFound;
