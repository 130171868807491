 /* eslint-disable */
 import React from 'react';
 import { withStyles } from '@material-ui/core/styles';
//  import Button from '@material-ui/core/Button';
 import Dialog from '@material-ui/core/Dialog';
 import MuiDialogTitle from '@material-ui/core/DialogTitle';
 import MuiDialogContent from '@material-ui/core/DialogContent';
 import MuiDialogActions from '@material-ui/core/DialogActions';
 import IconButton from '@material-ui/core/IconButton';
 import CloseIcon from '@material-ui/icons/Close';
 import Typography from '@material-ui/core/Typography';
 import InvoiceLabel from '../../Pages/Admin/Invoices/InvoiceDetails/InvoiceLabel';
 
 const styles = (theme) => ({
   root: {
     margin: 0,
     padding: theme.spacing(4),
     marginLeft: 25,
   },
   closeButton: {
     position: 'absolute',
     right: theme.spacing(1),
     top: theme.spacing(1),
     color: '#4d4d4d',
   },
 });
 
 const DialogTitle = withStyles(styles)((props) => {
   const { children, classes, onClose, ...other } = props;
   return (
     <MuiDialogTitle disableTypography className={classes.root} {...other}>
       <Typography variant="h6">{children}</Typography>
       {onClose ? (
         <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
           <CloseIcon />
         </IconButton>
       ) : null}
     </MuiDialogTitle>
   );
 });
 
 const DialogContent = withStyles((theme) => ({
   root: {
     padding: theme.spacing(4),
   },
 }))(MuiDialogContent);
 
 const DialogActions = withStyles((theme) => ({
   root: {
     margin: 0,
     padding: theme.spacing(1),
   },
 }))(MuiDialogActions);
 
 const LabelModal = ({open, setOpen}) => {

       //Print the Label
    const print = () => {
        window.print()
      }
     
   const handleClose = () => {
    setOpen(false);
   };
 
   return (
     <div>
         <form >
       <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth  maxWidth="lg">
             <DialogContent>
             <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{paddingLeft: '20px', color: 'white'}} />
             <InvoiceLabel />
            
             </DialogContent>
             <DialogActions>
             <button autoFocus onClick={print} variant="contained" style={{marginRight: '25px', backgroundColor:'#022b51', color:'#fff', borderRadius:5, padding:"8px 10px", marginBottom:10}} >
                 Print Label
             </button>
             </DialogActions>
       </Dialog>
         </form>
     </div>
   );
 }
 
 export default LabelModal