import React, { useState, useEffect } from 'react'
import MetaTags from 'react-meta-tags';
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { initialState } from '../../../../store/initialState';
import { getInvoice } from '../../../../store/invoices/actions'
import { toCommas } from '../../../../utils/commas';
import styles from './InvoiceDetails.module.css'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { Container, Grid } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
//import Spinner from '../../../../components/Spinner/Spinner'
import Breadcrumbs from "../../../../components/common/Breadcrumb";
import {axiosInstance3} from '../../../../api/config';
import { saveAs } from 'file-saver';

import Invoicedetailsheader from '../InvoiceDetails/InvoiceDetailsHeader';
import Logo from '../../../../assets/images/amgray-update-logo.png'
import { getConsignees } from '../../../../store/consignees/actions'

const InvoiceDetails = () => {
  const {consignees} = useSelector((state) => state.consignees);
    const location = useLocation()
    const [invoiceData, setInvoiceData] = useState(initialState)
    const [currency, setCurrency] = useState('')
    const [totalVolume, setTotalVolume] = useState(0)
    const [totalWeight, setTotalWeight] = useState(0)
    const [totalQuantity, setTotalQuantity] = useState(0)
    const [total, setTotal] = useState(0)
    const [ client, setClient] = useState([])
    const [origin, setOrigin] = useState('')
    const [destination, setDestination] = useState('')
    const [type, setType] = useState('')
    const [transportType, setTransportType] = useState('')
    const { id } = useParams()
    const { invoice } = useSelector((state) => state.invoices)
    const dispatch = useDispatch()
    const history = useHistory()
    const [sendStatus, setSendStatus] = useState(null)
    const [downloadStatus, setDownloadStatus] = useState(null)
    // eslint-disable-next-line
  
    const useStyles = makeStyles((theme) => ({
        root: {
          display: 'flex',
          '& > *': {
            margin: theme.spacing(1),
          },
        },
        large: {
          width: theme.spacing(12),
          height: theme.spacing(12),
        },
        table: {
            minWidth: 650,
          },
      }));
    

      useEffect(() => {
        dispatch(getConsignees());
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[location, dispatch])

    const classes = useStyles()
    useEffect(() => {
        dispatch(getInvoice(id));
      },[id, dispatch, location]);

      useEffect(() => {
        if(invoice) {
            //Automatically set the default invoice values as the ones in the invoice to be updated
            setInvoiceData(invoice)
            setClient(invoice.client)
            setOrigin(invoice.origin)
            setDestination(invoice.destination)
            setType(invoice.type)
            setTransportType(invoice.transportType)
            setCurrency(invoice.currency)
            setTotalVolume(invoice.totalVolume)
            setTotalWeight(invoice.totalWeight)
            setTotalQuantity(invoice.totalQuantity)
            setTotal(invoice.total)
        }
    }, [invoice, consignees])

    //Get the total amount paid
    let totalAmountReceived = 0
    for(var i = 0; i < invoice?.paymentRecords?.length; i++) {
        totalAmountReceived += Number(invoice?.paymentRecords[i]?.amountPaid)
    }

  const editInvoice = (id) => {
    history.push(`/admin/dashboard/edit/invoice/${id}`)
  }
  const createAndDownloadPdf = () => {
    setDownloadStatus('loading')
    axiosInstance3.post(`/create-pdf`, 
    { firstName: invoice.client.firstName,
      lastName: invoice.client.lastName,
      address: invoice.client.address,
      zipcode: invoice.client.zipcode,
      state: invoice.client.state,
      country: invoice.client.country,
      phone: invoice.client.phone,
      email: invoice.client.email,
      // dueDate: invoice.dueDate,
      date: invoice.createdAt,
      id: invoice.invoiceNumber,
      notes: invoice.notes,
      total: toCommas(invoice.total),
      type: invoice.type,
      origin:invoice.origin,
      destination:invoice.destination,
      transportType: invoice.transportType,
      items: invoice.items,
      status: invoice.status,
      totalVolume: invoice.totalVolume,
      totalWeight: invoice.totalWeight,
      totalQuantity: invoice.totalQuantity,
      totalAmountReceived: toCommas(totalAmountReceived),
      balanceDue: toCommas(total - totalAmountReceived),
      company: invoice.consignee,
    
  })
      .then(() => axiosInstance3.get(`/fetch-pdf`, {responseType: 'blob' }))
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

        saveAs(pdfBlob, 'invoice.pdf')
      }).then(() =>  setDownloadStatus('success'))
  }


  //SEND PDF INVOICE VIA EMAIL
  const sendPdf = (e) => {
    e.preventDefault()
    setSendStatus('loading')
    axiosInstance3.post(`/send-pdf`, 
    { firstName: invoice.client.firstName,
      lastName: invoice.client.lastName,
      address: invoice.client.address,
      state: invoice.client.state,
      country: invoice.client.country,
      zipcode: invoice.client.zipcode,
      phone: invoice.client.phone,
      email: invoice.client.email,
      // dueDate: invoice.dueDate,
      date: invoice.createdAt,
      id: invoice.invoiceNumber,
      notes: invoice.notes,
      total: toCommas(invoice.total),
      type: invoice.type,
      origin:invoice.origin,
      destination:invoice.destination,
      transportType: invoice.transportType,
      items: invoice.items,
      status: invoice.status,
      totalVolume: invoice.totalVolume,
      totalWeight: invoice.totalWeight,
      totalQuantity: invoice.totalQuantity,
      totalAmountReceived: toCommas(totalAmountReceived),
      balanceDue: toCommas(total - totalAmountReceived),
      link: `http://localhost:3000/invoice/${invoice._id}`,
      company: invoice.consignee,
  
  })
  .then(() => setSendStatus('success'))
      .catch((error) => {
        console.log(error)
        setSendStatus('error')
      })
  }

  const handleValidDate = (date) => {
  const date1 = moment(new Date(date)).format('DD MMM Y');

    return date1;
  }
  const handleValidTime = (time) => {
    const time1 = moment(time).format('h:mm:ss a');
    return time1;
  }
  

    return (
      <>
      <div className="page-content">
      <MetaTags>
          <title>Invoice</title>
        </MetaTags>
        <Breadcrumbs title="Invoice Details" breadcrumbItem="Invoice Details" />
        <Invoicedetailsheader invoiceData={invoiceData} sendPdf={sendPdf} sendStatus={sendStatus} createAndDownloadPdf={createAndDownloadPdf} downloadStatus={downloadStatus} editInvoice={editInvoice} />
      <div className={styles.PageLayout}>
        <div className={styles.invoiceLayout}>
          <Container>
          <Box m={2} pt={3}>
          <Typography
                  variant="overline"
                  style={{ color: "gray"}}
                  gutterBottom
                >
                  Time issued: {" "}
                    <b>{handleValidTime(invoice?.createdAt)}</b>
                </Typography>
                </Box>
            <Grid
              container
              style={{ padding: "10px 0px", justifyContent:"space-between"}}
            >
                <Grid>
                    <img
                      src={Logo}
                      alt="Amgray Logo"
                      className={styles.logo}
                    />
                  <Typography style={{marginLeft:20, fontSize: 17 }} variant="subtitle1" >6237 highway 6 south<br/>
                    Houston, Texas 77083<br/>
                         Tel: <strong>8326145180</strong>
                        </Typography> 
                </Grid>
              <Grid item style={{ marginRight: 40, textAlign: "right" }}>
                <Typography style={{ lineSpacing: 1, fontSize: 40, fontWeight: 600}}
                >
                  {type}
                </Typography>
                <Typography  style={{lineSpacing: 1, fontSize: 23, fontWeight: 600, textTransform: 'uppercase'}}>Invoice #: { transportType === 'AIR' ?  'AIR' + invoiceData.invoiceNumber : transportType === 'SEA' ?  'SEA' + invoiceData.invoiceNumber : 'LAND' + invoiceData.invoiceNumber }</Typography>
                {/* <Typography variant="body2"> */}
                  
                {/* </Typography> */}
                <Typography style={{ lineSpacing: 1, fontSize: 19, fontWeight: 400
                  }}
                >
                 Transportation Type: <strong>{transportType}</strong>
                </Typography>
                <Typography style={{ lineSpacing: 1, fontSize: 19, fontWeight: 400
                  }}
                >
                 Origin: <strong>{origin}</strong>
                </Typography>
                <Typography style={{ lineSpacing: 1, fontSize: 19, fontWeight: 400, marginTop:10,}}
                >
                 Destination: <strong>{destination}</strong>
                </Typography>
              </Grid>
            </Grid>
          </Container>
          <Divider />
          <Container>
            <Grid
              container
              style={{ marginTop: "5px", justifyContent:"space-between"}}
            >
              <Grid item>
                  
                {/* )} */}
                <Container style={{ marginBottom: "20px" }}>
                  <Typography
                    variant="overline"
                    style={{ paddingRight: "3px", fontSize: 19 }}
                    gutterBottom
                  >
                    Shipper
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    Shipper Name: <strong>{client?.firstName} {client?.lastName}</strong>
                  </Typography>
                  <Typography variant="body1">Address: <strong>{client?.address} {client?.state}, {client?.zipcode} {client?.country}</strong></Typography>
                  <Typography variant="body1">Phone: <strong>{client?.phone} </strong></Typography>
                  <Typography variant="body1"> Email: <strong> {client?.email} </strong></Typography>
                </Container>
                {/* Consignee */}
                <Container>
                    <Typography
                      variant="overline"
                      style={{fontSize: 19 }}
                      gutterBottom
                    >
                      Consignee:
                    </Typography>
                    <Typography variant="subtitle1">
                    <strong> {invoice?.consignee?.firstName} {invoice?.consignee?.lastName}</strong>
                    </Typography>
                    <Typography variant="body1">
                      <strong>{invoice?.consignee?.phone}</strong>
                    </Typography>
                    <Typography  variant="body1">
                     {invoice?.consignee?.email}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                    Address: <strong>{invoice?.consignee?.address}, {invoice?.consignee?.state} {invoice?.consignee?.zipcode}, {invoice?.consignee?.country}</strong>
                    </Typography>
                  </Container>
              </Grid>

              <Grid item style={{ marginRight: 20, textAlign: "right", fontSize: 20 }}>
                <Typography
                  variant="overline"
                  gutterBottom
                >
                  Date issued
                </Typography>
                <Typography variant="body1" gutterBottom>
                {handleValidDate(invoice?.createdAt)}
                </Typography>
                <Typography variant="overline" gutterBottom>
                  Amount
                </Typography>
                <Typography variant="h5" gutterBottom>
                  {currency} {toCommas(total.toFixed(2))}
                </Typography>
              </Grid>
            </Grid>
          </Container>

          <form>
            <div>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead >
                    <TableRow>
                      <TableCell style={{background:"#022b51" , color:"#fff"}} >Item</TableCell>
                      <TableCell style={{background:"#022b51" , color:"#fff"}} >Qty</TableCell>
                      <TableCell style={{background:"#022b51" , color:"#fff"}} >Weight(lbs)</TableCell>
                      <TableCell style={{background:"#022b51" , color:"#fff"}} >Length(inches)</TableCell>
                      <TableCell style={{background:"#022b51" , color:"#fff"}} >Width(inches)</TableCell>
                      <TableCell style={{background:"#022b51" , color:"#fff"}} >Height(inches)</TableCell>
                      <TableCell style={{background:"#022b51" , color:"#fff"}} >Volume</TableCell>
                      <TableCell style={{background:"#022b51" , color:"#fff"}} >Price</TableCell>
                      <TableCell style={{background:"#022b51" , color:"#fff"}} >Charges</TableCell>
                    
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoiceData?.items?.map((itemField, index) => (
                      <TableRow key={index}>
                        <TableCell scope="row" style={{ width: "40%" }}>
                          {" "}
                          <InputBase
                            style={{ width: "100%" }}
                            multiline={true}
                            outline="none"
                            sx={{ ml: 1, flex: 1 }}
                            type="text"
                            name="itemName"
                            value={itemField.itemName}
                            placeholder="Description"
                            readOnly
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="quantity"
                            value={itemField?.quantity}
                            placeholder="0"
                            readOnly
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="weight"
                            value={itemField?.weight}
                            readOnly
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="length"
                            value={itemField?.length}
                            readOnly
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="width"
                            value={itemField?.width}
                            readOnly
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="height"
                            value={itemField?.height}
                            readOnly
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="volume"
                            value={((itemField.length * itemField.width * itemField.height)/166).toFixed(2)}
                            readOnly
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            multiline={true}
                            type="number"
                            name="unitPrice"
                            value={itemField?.unitPrice}
                            placeholder="0"
                            readOnly
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <InputBase
                            sx={{ ml: 1, flex: 1}}
                            multiline={true} 
                         
                            type="number"
                            name="amount"
                            value={ (itemField.weight > ((itemField.length * itemField.width * itemField.height)/166) ? (itemField.unitPrice * itemField.weight) : (itemField.unitPrice * (itemField.length * itemField.width * itemField.height)/166)).toFixed(2)}
                            readOnly
                          />{" "}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className={styles.addButton}></div>
            </div>
            {/* start  Dimensions */}
            <div className={styles.invoiceSummary}>
            <div className={styles.summary}>Invoice Summary</div>
            <div className={styles.summaryItem}>
                <p>Total Weight:</p>
                <h5>{totalWeight.toFixed(2)} lbs</h5>
            </div>
            <div className={styles.summaryItem}>
                <p>Total Quantity:</p>
                <h5>{totalQuantity}</h5>
             
            </div>
        </div> 
            <div className={styles.invoiceSummary}>
              <div className={styles.summaryItem}>
              <p>Total Volume:</p>
              <h5>{(totalVolume).toFixed(2)} Vlbs</h5>
              </div>
              
              <div className={styles.summaryItem}>
                <p>Total</p>
                <h4>
                  {currency} {toCommas(total.toFixed(2))}
                </h4>
              </div>
              <div className={styles.summaryItem}>
                <p>Paid</p>
                <h5>
                  {currency} {toCommas((totalAmountReceived).toFixed(2))}
                </h5>
              </div>

              <div className={styles.summaryItem}>
                <p>Balance</p>
                <h4
                  style={{
                    color: "black",
                    fontSize: "18px",
                    lineHeight: "8px",
                  }}
                >
                {currency} {toCommas((total - totalAmountReceived).toFixed(2))}
                </h4>
              </div>
            </div>
            <div className={styles.note}>
              <h4>Notes</h4>
              <Typography>{invoiceData.notes}</Typography>
              <h6 className={styles.note}><b>LIMITATION OF LIABILITY:</b></h6>
              <p style={{color:'red'}}>The Company shall not bear any liability whatsoever for any form of loss,
                 theft or damage of any item(s) shipped by customers through AMGRAY LOGISTICS LTD.
                However, for customers who have paid for insurance on their goods, the liability 
                of the company shall lie only to the amount so covered by the insurance paid.  </p>
            </div>
          </form>
        </div>
      </div>
      </div>
      </>
    );
}

export default InvoiceDetails
