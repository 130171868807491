import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

import { useDispatch } from "react-redux";
import { Logout } from "../../../store/auth/actions";


const SidebarContent = props => {
  const dispatch = useDispatch()
  const LogoutHanlder = ()=>{
    dispatch(Logout())
 }

  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    function activateParentDropdown(item) {
      item.classList.add("active")
      const parent = item.parentElement
      const parent2El = parent.childNodes[1]
      if (parent2El && parent2El.id !== "side-menu") {
        parent2El.classList.add("mm-show")
      }
  
      if (parent) {
        parent.classList.add("mm-active")
        const parent2 = parent.parentElement
  
        if (parent2) {
          parent2.classList.add("mm-show") // ul tag
  
          const parent3 = parent2.parentElement // li tag
  
          if (parent3) {
            parent3.classList.add("mm-active") // li
            parent3.childNodes[0].classList.add("mm-active") //a
            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.add("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.add("mm-show") // li
                parent5.childNodes[0].classList.add("mm-active") // a tag
              }
            }
          }
        }
        scrollElement(item);
        return false
      }
      scrollElement(item);
      return false
    }

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/" className="">
                <i className="bx bx-home-circle"></i>
                <span>Overview</span>
              </Link>
            </li>
            <li>
              <Link to="/user/dashboard/freights" className=" ">
              <i className='bx bxs-plane-alt'></i>
                <span>Freights</span>
              </Link>
            </li>
            <li>
              <Link to="/user/dashboard/payments" className="">
              <i className='bx bxs-credit-card'></i>
                <span>Payments</span>
              </Link>
            </li>
            
            <li>
              <Link to="/user/dashboard/invoices" className="">
              <i className='bx bxs-receipt'></i>
                <span>Invoices</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/user/dashboard/track-freight" className="">
              <i className='bx bx-map'></i>
                <span>Track Freight</span>
              </Link> 
            </li> */}
            <li>
              <Link to="/user/dashboard/calculator" className="">
              <i className='bx bxs-calculator'></i>
                <span>Calculator</span>
              </Link> 
            </li>
            <li>
              <Link to="/user/dashboard/settings" className=" ">
              <i className="bx bx-cog" />
                <span>Settings</span>
              </Link>
            </li>
            <li>
              <Link onClick={LogoutHanlder} to="/login" className=" ">
              <i className='bx bx-log-out'></i>
                <span>Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,

}

export default withRouter((SidebarContent))
