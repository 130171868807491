
// User Pages
import UserFreights from '../Pages/Users/UserFreights';
import UserDashboard from '../Pages/Users/Dashboard';
import UserInvoicedetails from '../Pages/Users/Invoices/UserInvoiceDetails';
import UserInvoices from '../Pages/Users/Invoices/Invoices';
import TrackFreight from '../Pages/Users/Tracking/TrackFreight';
import UserSettings from '../Pages/Users/Settings/UserSettings';
import TrackingDetails from '../Pages/Users/Tracking/TrackingDetails';
import Calculator from '../Pages/Users/Calculator/Calculator';
import UsersPayment from '../Pages/Users/UsersPayment';

//Admin Pages
import Clients from '../Pages/Admin/Clients';
import Freights from '../Pages/Admin/Freights';
import Dashboard from '../Pages/Admin/Dashboard';
import Invoices from '../Pages/Admin/Invoices/Invoices';
import InvoiceDetails from '../Pages/Admin/Invoices/InvoiceDetails/InvoiceDetails';
import Invoice from '../Pages/Admin/Invoices/Invoice';
import AdminSettings from '../Pages/Admin/Settings/AdminSettings';
import Payments from '../Pages/Admin/Payments/Payments';
import Kyc from '../Pages/Admin/kyc/Kyc';
import Consignees from '../Pages/Admin/Consignees';
import KycDetails from '../Pages/Admin/kyc/KycDetails';
import Users from '../Pages/Admin/Users';


// Auth pages
//import NoAccess from '../Pages/NoAccess';
import Register from '../Pages/Auth/Register';
import Login from '../Pages/Auth/Login';
import ForgotPassword from '../Pages/Auth/ForgotPassword'
import NotFound from '../Pages/NotFound';
import ResetPassword from '../Pages/Auth/ResetPassword';

// public routes
import TermsOfUse from '../Pages/TermsOfUse';


const adminRoutes = [
    { path: "/admin/dashboard", component: Dashboard },
    { path: "/admin/dashboard/invoice", component: Invoice },
    { path: "/admin/dashboard/invoice/:id", component: InvoiceDetails},
    { path: "/admin/dashboard/edit/invoice/:id", component: Invoice },
    { path: "/admin/dashboard/clients", component: Clients },
    { path: "/admin/dashboard/freights", component: Freights },
    { path: "/admin/dashboard/invoices", component: Invoices },
    { path: "/admin/dashboard/settings", component: AdminSettings },
    { path: "/admin/dashboard/payments", component: Payments },
    { path: "/admin/dashboard/kyc", component: Kyc },
    { path: "/admin/dashboard/users/:id", component: KycDetails},
    { path: "/admin/dashboard/users", component: Users },
    { path: "/admin/dashboard/consignees", component: Consignees },
]


const userRoutes = [
    { path: "/user/dashboard", component: UserDashboard },
    { path: "/user/dashboard/freights", component: UserFreights },
    { path: "/user/dashboard/invoices", component: UserInvoices },
    { path: "/user/dashboard/invoice-details", component: UserInvoicedetails },
    { path: "/user/dashboard/track-freight", component: TrackFreight },
    { path: "/user/dashboard/tracking-details", component: TrackingDetails },
    { path: "/user/dashboard/settings", component: UserSettings },
    { path: "/user/dashboard/calculator", component: Calculator },
    { path: "/user/dashboard/payments", component: UsersPayment },

]

const authRoutes = [
    { path: "/login", component: Login },
    { path: "/register", component: Register },
    { path: "/terms-of-use", component: TermsOfUse },
    { path: "/forgot-password", component: ForgotPassword },
    { path: "/reset/:token", component: ResetPassword }
]


const noAccessRoutes = [
    
    {  path: "/*", component: NotFound }, 
]

export { adminRoutes, authRoutes,userRoutes, noAccessRoutes }
