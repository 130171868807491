import React from 'react';
import { Row, Col, Card } from "reactstrap"

import { useSelector } from 'react-redux';
const CardWelcome = () => {
  const { profiles } = useSelector((state) => state.profiles)
    return (
        <>
        <Col xl="4">
          <Card style={{background: "#022b51"}} className="bg-soft">
            <div>
              <Row>
                <Col xs="7">
                  <div className="text-primary p-3">
                    <h5 style={{color:"#fff"}}>Welcome Back ! </h5>
                    <p style={{color:"#fff"}} >{profiles?.firstName}</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </>
    );
}

export default CardWelcome;