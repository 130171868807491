import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import isEmpty from "../../utils/isEmpty"

const AdminMiddleware = ({component: Component,layout: Layout,profile, ...rest}) => (
 
  <Route
    {...rest}
    render={props => {
      if (profile === isEmpty) {
        return (
          <Redirect to="/login" replace/> 
        )
      } 
      else{
        if(profile?.result?.role !== "ADMIN"){
          return <Redirect to="/login" replace/> 
        }
     }
  

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)



AdminMiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default AdminMiddleware;
