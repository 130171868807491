import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from "prop-types"
import {
  Button, Card, CardBody, Col, Container, Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

//import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min'
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import { useSnackbar } from 'react-simple-snackbar'

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { createClient, updateClient, getClients, deleteClient } from '../../store/clients/actions'
//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";

const Clients = () => {
  const dispatch = useDispatch();
  const location = useLocation()

  const [currentId, setCurrentId] = useState(null)
  const [openSnackbar, closeSnackbar] = useSnackbar()
  // const [modal_backdrop, setmodal_backdrop] = useState(false)
  const singleClient = useSelector((state)=> currentId ? state.clients.clients.find((c) => c._id === currentId) : null)
  const {clients} = useSelector((state) => state.clients);

  const [clientData, setClientData] = useState({ name: '', email: '', phone: '', address: '', userId: '', businessName:'', firstName:'',lastName:''})
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
  const [modal, setModal] = useState(false)


  useEffect(() => {
    if(singleClient) {
      setClientData(singleClient)
    }
  }, [singleClient])

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('profile')))
     setClientData({...clientData, userId: user?.result?._id})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location])


  useEffect(() => {
    var checkId = user?.result?._id
    if(checkId !== undefined) {
      setClientData({...clientData, userId: [checkId]})
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location])


  const handleSubmitClient =(e)=> {
    e.preventDefault()
    if(currentId) {
      dispatch(updateClient(currentId, clientData, openSnackbar, closeSnackbar))
    } else {
      dispatch(createClient(clientData))
    }
     clear()
     handleClose()
}

const clear =() => {
  setCurrentId(null) 
  setClientData({ name: '', email: '', phone: '', address: '', businessName:'', firstName:'',lastName:'', state:'', country:'', zipcode:'', userId: [], })
}
  
const handleClose = () => {
  setModal(false);
};


const handleEditClient = (selectedClient) => {
  setModal((prevState) => !prevState)
  setCurrentId(selectedClient)
}


useEffect(() => {
  dispatch(getClients());
// eslint-disable-next-line react-hooks/exhaustive-deps
},[location, dispatch])


  const handleAddClient = () => {
    toggle()
  }


  const handleValidCustomerSubmit = () => {
    toggle()
  }


  // const handleDeleteClient = (id) => {

  // }

  //pagination customization

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing { from } to { to } of { size } Results
    </span>
  );

  const options  = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
      text: '10', value: 10
    }, {
      text: '15', value: 15
    }, {
      text: 'All', value: clients.length
    }]
  };

  const ClientColumns = [
    {
      text: "Client ID",
      dataField: "userId",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{row.userId}</p>
          
        </>
      ),
    },

    {
      text: "First Name",
      dataField: "firstName",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{row.firstName}</p>
          
        </>
      ),
    },
    {
      text: "Last Name",
      dataField: "lastName",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{row.lastName}</p>
          
        </>
      ),
    },
    {
      text: "Phone Number",
      dataField: "phone",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{row.phone}</p>
          
        </>
      ),
    },
   
    {
      text: "Email Address",
      dataField: "email",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p  className="mb-0">{row.email}</p>
        </>
      ),
    },
    {
      text: "Address",
      dataField: "address",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-0"> {row.zipcode} {row.address}</p>
        </>
      ),
    },
    {
      text: "State",
      dataField: "state",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-0">{row.state}</p>
        </>
      ),
    },
    {
      text: "Country",
      dataField: "country",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-0">{row.country}</p>
        </>
      ),
    },
    {
      text: "Business Name",
      dataField: "businessName",
      sort: true,
  
      formatter: (cellContent, row) => (
        <>
          <p className="mb-0">{row.businessName}</p>
        </>
      ),
    },

    {
      text: "Actions",
      dataField: "_idnothing",
      formatter: (cellContent, client) => (
        <>
         <UncontrolledDropdown direction="left">
          <DropdownToggle className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem onClick={() => handleEditClient(client._id)}>
              <i className="bx bxs-edit-alt text-success me-1" />
              Edit
            </DropdownItem>
            {/* <DropdownItem onClick={ handleDeleteClient(row._id && row._id)}> */}
            <DropdownItem onClick={() => dispatch(deleteClient(client._id, openSnackbar, closeSnackbar))}>
              <i className="fas fa-trash-alt text-danger me-1" />
              Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        </>
      ),
    },
  ]

  const toggle = () => {
    setModal(!modal)
  }

  
  const { SearchBar } = Search

  return (
    <>
    <div className="page-content">
        <MetaTags>
          <title>Amgray Logistics Clients</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Clients" breadcrumbItem="Clients" />
          <Row>


            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(options)}
                    keyField='_id'
                    columns={ClientColumns}
                    data={clients}
                  >
                    {({paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={clients || []}
                        columns={ClientColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                   style={{background:"#022b51"}}
                                    className="btn mb-2 me-2 app-button"
                                    onClick={handleAddClient}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Add Client
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                               
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    
                                    // defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                     keyField="id"
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                  />
                                </div>
                                <Modal
                                  isOpen={modal}
                                  toggle={toggle}
                                >
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {/* {!!isEdit ? "Edit Customer" : "Add Client"} */}
                                    {currentId? 'Edit Client' : 'Add new Client'}
                                  </ModalHeader>
                                  <ModalBody>
                                    <AvForm
                                      onValidSubmit={
                                        handleValidCustomerSubmit
                                      }
                                    >
                                      <Row form>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <AvField
                                              name="firstName"
                                              label="First Name"
                                              type="text"
                                              // errorMessage="Invalid name"
                                              // validate={{
                                              //   required: { value: true },
                                              // }}
                                              onChange={(e) => setClientData({...clientData, firstName: e.target.value})}
                                              value={clientData.firstName} 
                                            
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="lastName"
                                              label="Last Name"
                                              type="text"
                                              // errorMessage="Invalid name"
                                              // validate={{
                                              //   required: { value: true },
                                              // }}
                                              onChange={(e) => setClientData({...clientData, lastName: e.target.value})}
                                              value={clientData.lastName} 
                                            
                                            />
                                          </div>

                                          <div className="mb-3">
                                            <AvField
                                              name="phone"
                                              label="Phone Number"
                                              type="text"
                                              // errorMessage="Invalid Phone no"
                                              // validate={{
                                              //   required: { value: true },
                                              // }}
                                              onChange={(e) => setClientData({...clientData, phone: e.target.value})}
                                              value={clientData.phone} 
                                             
                                            />
                                          </div>

                                          <div className="mb-3">
                                            <AvField
                                              name="email"
                                              label="Email Address"
                                              type="email"
                                              // errorMessage="Invalid Email"
                                              // validate={{
                                              //   required: { value: true },
                                              // }}
                                              onChange={(e) => setClientData({...clientData, email: e.target.value})}
                                              value={clientData.email} 
                                              
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="businessName"
                                              label="Business Name"
                                              type="text"
                                              onChange={(e) => setClientData({...clientData, businessName: e.target.value})}
                                              value={clientData.businessName} 
                                             
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="zipcode"
                                              label="Zip Code"
                                              type="text"
                                              onChange={(e) => setClientData({...clientData, zipcode: e.target.value})}
                                              value={clientData.zipcode} 
                                             
                                            />
                                          </div>
                                          
                                          <div className="mb-3">
                                            <AvField
                                              name="address"
                                              label="Address"
                                              type="textarea"
                                              rows="3"
                                              onChange={(e) => setClientData({...clientData, address: e.target.value})}
                                              value={clientData.address} 
                                            
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="state"
                                              label="State"
                                              type="text"
                                              onChange={(e) => setClientData({...clientData, state: e.target.value})}
                                              value={clientData.state} 
                                             
                                            />
                                          </div>
                                          
                                          <div className="mb-3">
                                            <AvField
                                             onChange={(e) => setClientData({...clientData, country: e.target.value})}
                                             value={clientData.country} 
                                            type="select" 
                                            name="country"
                                             label="Country">
                                            <option value='United States'>United States</option>
                                              <option value='Åland Islands'>Åland Islands</option>
                                              <option value='Albania'>Albania</option>
                                              <option value='Algeria'>Algeria</option>
                                              <option value='Andorra'>Andorra</option>
                                              <option value='Angola'>Angola</option>
                                              <option value='Argentina'>Argentina</option>
                                              <option value='Armenia'>Armenia</option>
                                              <option value='Aruba'>Aruba</option>
                                              <option value='Australia'>Australia</option>
                                              <option value='Austria'>Austria</option>
                                              <option value='Azerbaijan'>Azerbaijan</option>
                                              <option value='Bahamas'>Bahamas</option>
                                              <option value='Bangladesh'>Bangladesh</option>
                                              <option value='Barbados'>Barbados</option>
                                              <option value='Belarus'>Belarus</option>
                                              <option value='Belgium'>Belgium</option>
                                              <option value='Belize'>Belize</option>
                                              <option value='Benin'>Benin</option>
                                              <option value='Botswana'>Botswana</option>
                                              <option value='Brazil'>Brazil</option>
                                              <option value='BN'>Brunei Darussalam</option>
                                              <option value='BG'>Bulgaria</option>
                                              <option value='Bulgaria'>Burkina Faso</option>
                                              <option value='Burundi'>Burundi</option>
                                              <option value='Cambodia'>Cambodia</option>
                                              <option value='Cameroon'>Cameroon</option>
                                              <option value='Canada'>Canada</option>
                                              <option value='Cape Verde'>Cape Verde</option>
                                              <option value='Central African Republic'>Central African Republic</option>
                                              <option value='Chad'>Chad</option>
                                              <option value='Chile'>Chile</option>
                                              <option value='China'>China</option>
                                              <option value='Colombia'>Colombia</option>
                                              <option value='Congo'>Congo</option>
                                              <option value='Costa Rica'>Costa Rica</option>
                                              <option value='Côte d"Ivoire'>Côte d'Ivoire</option>
                                              <option value='Croatia'>Croatia</option>
                                              <option value='Cuba'>Cuba</option>
                                              <option value='Cyprus'>Cyprus</option>
                                              <option value='Czech Republic'>Czech Republic</option>
                                              <option value='Denmark'>Denmark</option>
                                              <option value='Djibouti'>Djibouti</option>
                                              <option value='Dominica'>Dominica</option>
                                              <option value='Dominican Republic'>Dominican Republic</option>
                                              <option value='Ecuador'>Ecuador</option>
                                              <option value='Egypt'>Egypt</option>
                                              <option value='El Salvador'>El Salvador</option>
                                              <option value='Equatorial Guinea'>Equatorial Guinea</option>
                                              <option value='Eritrea'>Eritrea</option>
                                              <option value='Estonia'>Estonia</option>
                                              <option value='Ethiopia'>Ethiopia</option>
                                              <option value='Finland'>Finland</option>
                                              <option value='France'>France</option>
                                                <option value='Gabon'>Gabon</option>
                                              <option value='Gambia'>Gambia</option>
                                              <option value='Germany'>Germany</option>
                                              <option value='Ghana'>Ghana</option>
                                              <option value='Greece'>Greece</option>
                                              <option value='Guinea'>Guinea</option>
                                              <option value='Guinea-Bissau'>Guinea-Bissau</option>
                                              <option value='Honduras'>Honduras</option>
                                              <option value='Hong Kong'>Hong Kong</option>
                                              <option value='Hungary'>Hungary</option>
                                              <option value='Iceland'>Iceland</option>
                                              <option value='India'>India</option>
                                              <option value='Indonesia'>Indonesia</option>
                                              <option value='Ireland'>Ireland</option>
                                              <option value='Israel'>Israel</option>
                                              <option value='Italy'>Italy</option>
                                              <option value='Jamaica'>Jamaica</option>
                                              <option value='Japan'>Japan</option>
                                              <option value='Jordan'>Jordan</option>
                                              <option value='Kazakhstan'>Kazakhstan</option>
                                              <option value='Kenya'>Kenya</option>
                                              <option value='Kuwait'>Kuwait</option>
                                              <option value='Lebanon'>Lebanon</option>
                                              <option value='Lesotho'>Lesotho</option>
                                              <option value='Liberia'>Liberia</option>
                                              <option value='Libya'>Libya</option>
                                              <option value='Lithuania'>Lithuania</option>
                                              <option value='Luxembourg'>Luxembourg</option>
                                              <option value='Madagascar'>Madagascar</option>
                                              <option value='Malawi'>Malawi</option>
                                              <option value='Malaysia'>Malaysia</option>
                                              <option value='MV'>Maldives</option>
                                              <option value='Maldives'>Mali</option>
                                              <option value='Malta'>Malta</option>
                                              <option value='Mauritania'>Mauritania</option>
                                              <option value='Mauritius'>Mauritius</option>
                                              <option value='Mexico'>Mexico</option>
                                              <option value='Monaco'>Monaco</option>
                                              <option value='Mongolia'>Mongolia</option>
                                              <option value='Morocco'>Morocco</option>
                                              <option value='Mozambique'>Mozambique</option>
                                              <option value='Myanmar'>Myanmar</option>
                                              <option value='Namibia'>Namibia</option>
                                              <option value='Nepal'>Nepal</option>
                                              <option value='Netherlands'>Netherlands</option>
                                              <option value='New Zealand'>New Zealand</option>
                                              <option value='Niger'>Niger</option>
                                              <option value='Nigeria'>Nigeria</option>
                                              <option value='Norway'>Norway</option>
                                              <option value='Oman'>Oman</option>
                                              <option value='Pakistan'>Pakistan</option>
                                              <option value='Paraguay'>Paraguay</option>
                                              <option value='Peru'>Peru</option>
                                              <option value='Philippines'>Philippines</option>
                                              <option value='Poland'>Poland</option>
                                              <option value='Portugal'>Portugal</option>
                                              <option value='Puerto Rico'>Puerto Rico</option>
                                              <option value='Qatar'>Qatar</option>
                                              <option value='Romania'>Romania</option>
                                              <option value='Russian Federation'>Russian Federation</option>
                                              <option value='Rwanda'>Rwanda</option>
                                              <option value='Saudi Arabia'>Saudi Arabia</option>
                                              <option value='Senegal'>Senegal</option>
                                              <option value='Serbia'>Serbia</option>
                                              <option value='Seychelles'>Seychelles</option>
                                              <option value='Sierra Leone'>Sierra Leone</option>
                                              <option value='Singapore'>Singapore</option>
                                              <option value='Somalia'>Somalia</option>
                                              <option value='South Africa'>South Africa</option>
                                              <option value='Spain'>Spain</option>
                                              <option value='Sri Lanka'>Sri Lanka</option>
                                              <option value='Sudan'>Sudan</option>
                                              <option value='Sweden'>Sweden</option>
                                              <option value='Switzerland'>Switzerland</option>
                                              <option value='Tanzania'>Tanzania</option>
                                              <option value='Thailand'>Thailand</option>
                                              <option value='Togo'>Togo</option>
                                              <option value='Trinidad and Tobago'>Trinidad and Tobago</option>
                                              <option value='Tunisia'>Tunisia</option>
                                              <option value='Turkey'>Turkey</option>
                                              <option value='Uganda'>Uganda</option>
                                              <option value='Ukraine'>Ukraine</option>
                                              <option value='United Arab Emirates'>United Arab Emirates</option>
                                              <option value='United Kingdom'>United Kingdom</option>
                                              <option value='Uruguay'>Uruguay</option>
                                              <option value='Zambia'>Zambia</option>
                                              <option value='Zimbabwe'>Zimbabwe</option>
                                          </AvField>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button onClick={handleSubmitClient}
                                            style={{background:"#022b51", color:"#fff"}}
                                              type="submit"
                                              className="btn save-customer"
                                            >
                                              Save Client
                                            </button> 

                                          </div>
                                        </Col>
                                      </Row>
                                    </AvForm>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>

                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

Clients.propTypes = {
  clients: PropTypes.array,
 
}
export default Clients;