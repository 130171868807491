import React from 'react';
import { Col, Card, CardBody, CardTitle } from "reactstrap"
import ColumnWithDataLabels from "./ColumnWithDataLabels"

const SalesAnalytics = ({paymentHistory}) => {
  return (
    <>
    <Col xl="6">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">
                  Monthly Earning{" "}
                </CardTitle>
                <ColumnWithDataLabels paymentHistory={paymentHistory} />
              </CardBody>
            </Card>
          </Col>
    </>
  );
}

export default SalesAnalytics;
