import React, { useState, useEffect} from 'react'
import styles from './Invoice.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
// import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { toCommas } from '../../../utils/commas';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
// import DateFnsUtils from '@date-io/date-fns';
// import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { Container, Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

/// bootstrap
import MetaTags from 'react-meta-tags';

//Import Breadcrumb
import Breadcrumbs from "../../../components/common/Breadcrumb"
//Import Logo
import logo from "../../../assets/images/amgray-update-logo.png"

import AddClient from './AddClient';
import AddConsignee from './AddConsignee'
// import InvoiceType from './InvoiceType';
import TransportType from './TransportType'
import Origin from './Origin'
import Destination from './Destination'

import {initialState} from '../../../store/initialState';
import currencies from '../../../data/currencies.json'
import { createInvoice, getInvoice, updateInvoice } from '../../../store/invoices/actions';
import { getClients } from '../../../store/clients/actions';
import { getConsignees } from '../../../store/consignees/actions'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  table: {
      minWidth: 650,
    },

  headerContainer: {
      // display: 'flex'
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(1),
  }
}));

const Invoice = () => {
  const [invoiceData, setInvoiceData] = useState(initialState)
  const [ rates, setRates] = useState(0)
  const [ length, setLength] = useState(0)
  const [ width, setWidth] = useState(0)
  const [ height, setHeight] = useState(0)
  const [ weight, setWeight] = useState(0)
  const currency = currencies[0].value
  const [subTotal, setSubTotal] = useState(0)
  const [totalVolume, setTotalVolume] = useState(0)
  const [totalWeight, setTotalWeight] = useState(0)
  const [totalQuantity, setTotalQuantity] = useState(0)
  const [quantity, setQuantity] = useState(0)
  const [total, setTotal] = useState(0)
  const today = new Date();
//   const [selectedDate, setSelectedDate] = useState(today.getTime() + 7 * 24 * 60 * 60 * 1000);
  const [ client, setClient] = useState(null)
  const [origin, setOrigin] = useState('George Bush Int. Airport')
  const [destination, setDestination] = useState('Murtala Muhammed Int. Airport')
  const [type, setType] = useState('Invoice')
  const [transportType, setTransportType] = useState('AIR')
  const [status, setStatus ] = useState('')
  const { id } = useParams()
  const clients = useSelector((state) => state.clients.clients)
  const { invoice } = useSelector((state) => state.invoices);
  const {consignees} = useSelector((state) => state.consignees);
  const [ consignee, setConsignee] = useState(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const user = JSON.parse(localStorage.getItem('profile'))

    useEffect(() => {
      dispatch(getInvoice(id));
      // eslint-disable-next-line
    }, [id]);

  useEffect(() => {
      dispatch(getClients());
      // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    dispatch(getConsignees());
    // eslint-disable-next-line
}, [dispatch]);
 
  useEffect(() => {
      if(invoice) {
          //Automatically set the default invoice values as the ones in the invoice to be updated
          setInvoiceData(invoice)
          setRates(invoice.rates)
          setClient(invoice.client)
          setOrigin(invoice.origin)
          setDestination(invoice.destination)
          setType(invoice.type)
          setTransportType(invoice.transportType)
          setStatus(invoice.status)
        //   setSelectedDate(invoice.dueDate)
          setConsignee(invoice.consignee)
          setLength(invoice.length)
          setWidth(invoice.width)
          setHeight(invoice.height)
          setWeight(invoice.weight)
          setQuantity(invoice.quantity)     
      }
  }, [invoice])


  const clientsProps = {
      options: clients,
      getOptionLabel: (option) => option.firstName + ' ' + option.lastName
    };

    const consigneesProps = {
        options: consignees,
        getOptionLabel: (option) => option.firstName + ' ' + option.lastName
      };
  
  // Change handler for dynamically added input field
  const handleChange =(index, e) => {
      const values = [...invoiceData.items]
      values[index][e.target.name] = e.target.value
      setInvoiceData({...invoiceData, items: values})
      
  }

  useEffect(() => {
          //Get the subtotal
          const subTotal =()=> {
          var arr = document.getElementsByName("amount");
          var subtotal = 0;
          for(var i = 0; i < arr.length; i++) {
              if(arr[i].value) {
                  subtotal += +arr[i].value;
              }
              // document.getElementById("subtotal").value = subtotal;
              setSubTotal(subtotal)
          }
      }
      subTotal() 
  }, [invoiceData])



  useEffect(() => {
    //Get total volume
    const totalVolume =()=> {
    var arr = document.getElementsByName("volume");
    var volume = 0;
    for(var i = 0; i < arr.length; i++) {
        if(arr[i].value) {
            volume += +arr[i].value;
        }
        // document.getElementById("subtotal").value = subtotal;
        setTotalVolume(volume)
    }
}
totalVolume() 
}, [invoiceData])


useEffect(() => {
    //Get total weight
    const totalWeight =()=> {
    var arr = document.getElementsByName("weight");
    var weight = 0;
    for(var i = 0; i < arr.length; i++) {
        if(arr[i].value) {
            weight += +arr[i].value;
        }
        setTotalWeight(weight)
    }
}
totalWeight() 
}, [invoiceData])

// Quantity 
useEffect(() => {
    //Get total quantity
    const totalQuantity =()=> {
    var arr = document.getElementsByName("quantity");
    var quantity = 0;
    for(var i = 0; i < arr.length; i++) {
        if(arr[i].value) {
            quantity += +arr[i].value;
        }
        setTotalQuantity(quantity)
    }
}
totalQuantity() 
}, [invoiceData])
///////

  useEffect(() => {
      const total =() => {
          const overallSum = subTotal
          setTotal(overallSum)
      }
      total()
  }, [invoiceData, subTotal])
  
  const handleAddField = (e) => {
      e.preventDefault()
      setInvoiceData((prevState) => ({...prevState, items: [...prevState.items,  {itemName: '', unitPrice: '', quantity: '', amount: '', weight:'', length:'', width:'', height:'' }]}))
  }

  const handleRemoveField =(index) => {
      const values = invoiceData.items
      values.splice(index, 1)
      setInvoiceData((prevState) => ({...prevState, values}))
  }

//   const handleValidDate = (date) => {
//     const date1 = moment(new Date(date)).format('DD MMM Y');
//     return date1;
//   }
  
  const handleSubmit =  async (e) => {
      e.preventDefault()
      if(invoice) {
       dispatch(updateInvoice(invoice._id, {
           ...invoiceData, 
           subTotal: subTotal,
           totalVolume:totalVolume,
           totalWeight:totalWeight,
           totalQuantity:totalQuantity,
           total: total, 
           rates: rates, 
           currency: currency, 
        //    dueDate: selectedDate,
        //    createdAt: today,
           client,
           consignee,
           origin:origin,
           destination:destination,
           type: type,
           transportType: transportType,
           status: status,
           weight: weight,
           length: length,
           width: width,
           height: height,
           quantity:quantity,
          })) 
          history.push(`/admin/dashboard/invoice/${invoice._id}`)

      } else {

      dispatch(createInvoice({
          ...invoiceData, 
          subTotal: subTotal, 
          total: total,
          totalVolume:totalVolume, 
          rates: rates, 
          currency: currency,
          createdAt: today, 
        //   dueDate: selectedDate, 
          client, 
          consignee,
          origin:origin,
          destination:destination,
          type: type, 
          transportType: transportType,
          status: status,
          weight:weight,
          quantity:quantity,
          length: length,
          totalWeight: totalWeight,
          totalQuantity:totalQuantity,
          width: width,
          height: height, 
          paymentRecords: [], 
          creator: [user?.result?._id] }, 
          history
          ))
      }
  }

  const classes = useStyles()
  const [openClient, setOpenClient] = useState(false);
  const [openConsignee, setOpenConsignee] = useState(false);

  const CustomPaper = (props) => {
      return <Paper elevation={3} {...props} />;
    };

  return (
   <>
   <div className="page-content">
   <MetaTags>
  <title>Invoice</title>
  </MetaTags>
   <Breadcrumbs title="Invoice" breadcrumbItem="Invoice" />
  <div className={styles.invoiceLayout}>
        <form onSubmit={handleSubmit} className="mu-form">
            <AddClient setOpenClient={setOpenClient} openClient={openClient} />
            <AddConsignee setOpenConsignee={setOpenConsignee} openConsignee={openConsignee} />
            <Container  className={classes.headerContainer}>
                
                <Grid style={{justifyContent:"space-between"}}container>
                    <Grid item>
                        <Avatar alt="Logo" variant='square' src={logo} className={classes.large} />
                        <Typography variant="body2" >6237 highway 6 south<br/>
                         Houston, Texas 77083<br/>
                         Tel: <strong>8326145180</strong>
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography style={{lineSpacing: 1, fontSize: 35, fontWeight: 700, marginTop:10}}>INVOICE</Typography>
                        <TransportType transportType={transportType} setTransportType={setTransportType} />
                        <Typography  style={{lineSpacing: 1, fontSize: 23, fontWeight: 600, textTransform: 'uppercase'}}>Invoice #: {invoiceData.invoiceNumber}</Typography>
                    </Grid>
                </Grid >
                {/* Origin */}
                <Origin origin={origin} setOrigin={setOrigin} />
                 {/* Destination */}
                 <Destination destination={destination} setDestination={setDestination} />
            </Container>
            <Divider />
            <Container>
                <Grid container style={{marginTop: '30px',  justifyContent:"space-between"}} >
                    <Grid item style={{width: '50%'}}>
                        <Container>

                            <Typography variant="overline" style={{color: 'gray', paddingRight: '3px'}} gutterBottom>Shipper</Typography>
                            {client  && (
                                <>
                                    <Typography variant="subtitle2" gutterBottom> Names: {client?.firstName} {client?.lastName}</Typography>
                                    <Typography variant="body2">Address: {client?.address} {client?.state},{client?.zipcode}, {client?.country}</Typography>
                                    <Typography variant="body2" >Phone Number: {client?.phone}</Typography>
                                    <Typography variant="body2" >Email Address: {client?.email}</Typography>
                                    <Button color="primary" size="small" style={{textTransform: 'none'}} onClick={()=> setClient(null)}>Change</Button>
                                </>
                            )}
                            <div style={client? {display: 'none'} :  {display: 'block'}}>
                                <Autocomplete
                                            {...clientsProps}
                                            PaperComponent={CustomPaper}
                                                renderInput={(params) => <TextField {...params}
                                                required={!invoice && true} 
                                                label="Select Client" 
                                                margin="normal" 
                                                variant="outlined"
                                                />}
                                            value={clients?.firstName}
                                            onChange={(event, value) => setClient(value)} 
                                    />

                            </div>
                            {!client && 
                                <>
                                <Grid item style={{paddingBottom: '10px'}}>
                                    <Chip
                                        avatar={<Avatar style={{background:"#022b51", color:"#fff"}}>+</Avatar>}
                                        label="New Client"
                                        onClick={() => setOpenClient(true)}
                                        variant="outlined"
                                    />
                                </Grid>
                                </>
                            }
                        </Container>

                        {/* Consignees logic starts here */}
                    </Grid>
                    <Grid item style={{marginRight: 2, textAlign: 'right'}}>
                    <Typography variant="overline" style={{color: 'gray', paddingRight: '3px'}} gutterBottom>Consignee</Typography>
                    <Grid>
                    {consignee  && (
                                <>
                                    <Typography variant="subtitle2" gutterBottom>{consignee.firstName} {consignee.lastName}</Typography>
                                    <Typography variant="body2">{consignee.address} {consignee.state}, {consignee.zipcode}, {consignee.country}</Typography>
                                    <Typography>{consignee.state}, {consignee.country}</Typography>
                                    <Typography variant="body2" >{consignee.phone}</Typography>
                                    <Typography variant="body2" >{consignee.email}</Typography>
                                    <Button color="primary" size="small" style={{textTransform: 'none'}} onClick={()=> setConsignee(null)}>Change</Button>
                                </>
                            )}          
                    <div style={consignee? {display: 'none'} :  {display: 'block'}}>
                                <Autocomplete
                                            {...consigneesProps}
                                            PaperComponent={CustomPaper}
                                                renderInput={(params) => <TextField {...params}
                                                required={!consignee && true} 
                                                label="Select Consignee" 
                                                margin="normal" 
                                                variant="outlined"
                                                
                                                />}
                                            value={consignees?.firstName}
                                            onChange={(event, value) => setConsignee(value)} 
                                    />
                            </div>
                    {!consignee && 
                                <>
                                <Grid item style={{paddingBottom: '10px', marginRight:'30px'}}>
                                    <Chip
                                        avatar={<Avatar style={{background:"#022b51", color:"#fff"}}>+</Avatar>}
                                        label="New Consignee"
                                        onClick={() => setOpenConsignee(true)}
                                        variant="outlined"
                                    />
                                </Grid>
                                </>
                            }  
                    </Grid>
                        {/* <Typography variant="overline" style={{color: 'gray'}} gutterBottom>Issued Date</Typography> */}
                        {/* <Typography variant="body2" gutterBottom>{handleValidDate(invoice?.createdAt)}</Typography> */}
                        {/* <Typography variant="body2" gutterBottom>{handleValidDate(today)}</Typography> */}
                        <Typography variant="overline" gutterBottom>Charges</Typography>
                        <Typography variant="h6" gutterBottom>{currency} {(total).toFixed(2)}</Typography>
                    </Grid>
                </Grid>
            </Container>
    <div>
        <TableContainer component={Paper} className="tb-container">
        <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Qty</TableCell>
                <TableCell>Weight (lbs)</TableCell>
                <TableCell>Length (inches)</TableCell>
                <TableCell>Width (inches)</TableCell>
                <TableCell>Height (inches)</TableCell>
                <TableCell>Volume</TableCell>
                <TableCell>Price ($)</TableCell>
                <TableCell>Charges</TableCell>
                <TableCell>Action</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {invoiceData.items.map((itemField, index) => (
                <TableRow key={index}>
                <TableCell  scope="row" style={{width: '40%' }}> <InputBase style={{width: '100%'}} multiline={true} outline="none" sx={{ ml: 1, flex: 1 }} type="text" name="itemName" onChange={e => handleChange(index, e)} value={itemField.itemName} placeholder="description" /> </TableCell>
                <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()} name="quantity" onChange={e => handleChange(index, e)} value={itemField.quantity} placeholder="0" /> </TableCell>
                <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()} name="weight" onChange={e => handleChange(index, e)} value={itemField.weight} placeholder="0" /></TableCell>
                <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()} name="length" onChange={e => handleChange(index, e)} value={itemField.length} placeholder="0" /> </TableCell>
                <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()} name="width" onChange={e => handleChange(index, e)} value={itemField.width} placeholder="0" /> </TableCell>
                <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()} name="height" onChange={e => handleChange(index, e)} value={itemField.height} placeholder="0" /> </TableCell>
                <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} type="number" name="volume" onChange={e => handleChange(index, e)}  value={((itemField.length * itemField.width * itemField.height)/166).toFixed(2) } disabled /> </TableCell>
                <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 1 }} multiline={true} type="number" onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()} name="unitPrice" onChange={e => handleChange(index, e)} value={itemField.unitPrice} placeholder="0" /> </TableCell>
                <TableCell align="right"> <InputBase sx={{ ml: 1, flex: 3}}   multiline={true}  type="number" name="amount" onChange={e => handleChange(index, e)}  value={ (itemField.weight > ((itemField.length * itemField.width * itemField.height)/166) ? (itemField.unitPrice * itemField.weight) : (itemField.unitPrice * (itemField.length * itemField.width * itemField.height)/166)).toFixed(2)} disabled /> </TableCell>
               
                <TableCell align="right"> 
                    <IconButton onClick={() =>handleRemoveField(index)}>
                        <DeleteIcon style={{width: '25px', height: '25px', color:"#022b51"}}/>
                    </IconButton>
                </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
            <div className={styles.addButton}>
            <button style={{background:"#022b51", color:"#fff"}} onClick={handleAddField}>+</button>
            </div>
        </div>


        <div className={styles.invoiceSummary}>
              {/* <div>Total Weight</div> */}
            <div className={styles.summary}>Invoice Summary</div>
            <div className={styles.summaryItem}>
                <p>Total Weight:</p>
                <h5>{totalWeight.toFixed(2)} lbs</h5>
            </div>
        </div> 
      
        <div className={styles.invoiceSummary}>
            <div className={styles.summaryItem}>
              <p>Total Volume:</p>
                <h5>{totalVolume.toFixed(2)} Vlbs</h5>
            </div>
            <div className={styles.summaryItem}>
                <p>Total</p>
                <h4 style={{color: "#000", fontSize: "18px", lineHeight: "8px"}}>{currency} {toCommas(total.toFixed(2))}</h4>
            </div>
            
        </div>   
            <div className={styles.note}>
                <h4>Notes</h4>
                <textarea
                    placeholder="Provide additional details or terms of service"
                    onChange={(e) => setInvoiceData({...invoiceData, notes: e.target.value})}
                    value={invoiceData.notes}
                />
            </div>

            {/* <button className={styles.submitButton} type="submit">Save and continue</button> */}
            <Grid container style={{justifyContent:"center"}}>
            <Button
                variant="contained"
                style={{justifyContent: 'center', background:"#022b51", color:"#fff" , borderRadius:"0.25rem"}}
                type="submit"
                size="large"
                className={classes.button}
                // startIcon={<SaveIcon />}
            >
                Save and Continue
            </Button>
            </Grid>
        </form>
    </div>
    </div>
   </>
  );
}

export default Invoice;
    