import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { initialState } from '../../../../store/initialState';
import { getInvoice } from '../../../../store/invoices/actions'
import styles from './InvoiceDetails.module.css'
import moment from 'moment'
import { Container, Grid } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Logo from '../../../../assets/images/amgray-update-logo.png'
import { getConsignees } from '../../../../store/consignees/actions'

const InvoiceLabel = () => {
  const {consignees} = useSelector((state) => state.consignees);
    const location = useLocation()
    const [invoiceData, setInvoiceData] = useState(initialState)
    const [totalQuantity, setTotalQuantity] = useState(0)
    const [origin, setOrigin] = useState('')
    const [destination, setDestination] = useState('')
    const [transportType, setTransportType] = useState('')
    const { id } = useParams()
    const { invoice } = useSelector((state) => state.invoices)
    const dispatch = useDispatch()
    
      useEffect(() => {
        dispatch(getConsignees());
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[location, dispatch])

    useEffect(() => {
        dispatch(getInvoice(id));
      },[id, dispatch, location]);

      useEffect(() => {
        if(invoice) {
            //Automatically set the default invoice values as the ones in the invoice to be updated
            setInvoiceData(invoice)
  
            setOrigin(invoice.origin)
            setDestination(invoice.destination)
            setTransportType(invoice.transportType)
            setTotalQuantity(invoice.totalQuantity)
        }
    }, [invoice, consignees])

    const handleValidDate = (date) => {
      const date1 = moment(new Date(date)).format('DD MMM Y');
      return date1;
    }


    return (
      <>
      <div className="page-content">
      <div className={styles.PageLayout}>
        <div className={styles.invoiceLayout}>
          <Container>
          <Box m={2} pt={3}>
                </Box>
            <Grid
              container
              style={{ padding: "10px 0px", justifyContent:"space-between"}}
            >
                <Grid>
                    <img
                      src={Logo}
                      alt="Amgray Logo"
                      className={styles.logo}
                    />
                  <Typography style={{marginLeft:20 }} variant="body1">  <strong>6237 highway 6 south<br/>
                 
                    Houston, Texas 77083<br/>
                         Tel:8326145180</strong>
                        </Typography> 
                </Grid>
              <Grid item style={{ marginRight: 40, textAlign: "right" }}>
              <Typography  style={{lineSpacing: 1, fontSize: 23, fontWeight: 600, textTransform: 'uppercase'}}>Invoice #: { transportType === 'AIR' ?  'AIR' + invoiceData.invoiceNumber : transportType === 'SEA' ?  'SEA' + invoiceData.invoiceNumber : 'LAND' + invoiceData.invoiceNumber }</Typography>
                <Typography style={{ lineSpacing: 1, fontSize: 19, fontWeight: 300}}><strong>Transportation Type: {transportType}</strong>
                </Typography>
                <Typography style={{ lineSpacing: 1, fontSize: 19, fontWeight: 300}}
                >
                 <strong>Origin:{origin}</strong>
                </Typography>
                <Typography style={{ lineSpacing: 1, fontSize: 19, fontWeight: 300, marginTop:10,}}
                >
                 <strong>Destination: {destination}</strong>
                </Typography>
              </Grid>
            </Grid>
          </Container>
          <Divider />
          <Container>
            <Grid
              container
              style={{ marginTop: "5px", justifyContent:"space-between"}}
            >
              <Grid item>
                
                {/* Consignee */}
                <Container>
                    <Typography
                      variant="overline"
                      style={{ fontSize: "19px" }}
                      gutterBottom
                    >
                      Consignee:
                    </Typography>
                    <Typography variant="h6">
                     {invoice?.consignee?.firstName} {invoice?.consignee?.lastName}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                    <p>Address: </p>{invoice?.consignee?.address}, 
                    </Typography>
                 
                    <Typography variant="h6" gutterBottom>
                    {invoice?.consignee?.country}, {invoice?.consignee?.state} {invoice?.consignee?.zipcode}.
                    </Typography>
                    
                  </Container>
                  
              </Grid>
              <Grid item style={{ marginRight: 20, textAlign: "right" }}>
              <Typography
                  variant="overline"
                  style={{fontSize: "19px" }}
                  gutterBottom
                >
                  Date issued
                </Typography>
                <Typography variant="h6" gutterBottom>
                {handleValidDate(invoice?.createdAt)}
                </Typography>
              </Grid>
              
              <Typography variant="h6">Total Quantity:  {totalQuantity}</Typography>
             
            </Grid>
          </Container>
        </div>
      </div>
      </div>
      </>
    );
}

export default InvoiceLabel
