
import { ALL_USERS, UPDATE_USER, DELETE_USER, FETCH_USERS_BY_USER, FETCH_USER, APPROVE_USER,  START_LOADING, END_LOADING } from './actionTypes'

const usersReducer = (state = { isLoading: true, users: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
      case ALL_USERS:
        return {
          ...state,
          users: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
      case FETCH_USERS_BY_USER:
        return { ...state, users: action.payload };

      case FETCH_USER:
        return { ...state, user: action.payload.user };
      case UPDATE_USER:
        return { ...state, users: state.users.map((user) => (user._id === action.payload._id ? action.payload : user)) };
        case APPROVE_USER:
          return { ...state, users: state.users.map((user) => (user._id === action.payload._id ? action.payload : user)) };
      case DELETE_USER:
        return { ...state, users: state.users.filter((user) => user._id !== action.payload) };
      default:
        return state;
    }
  };

  export default usersReducer
