export function calculate_shipping(weight = 0, length, breath, height) {
  let cost_per_kg = 4;
  // let cost_per_cm;
  // let cost_per_breath;
  // let cost_per_height;
  //let shipping_cost_multiplier = 3;
  let shipping_cost;
  let weight_in_kg = parseFloat(weight); // multiply input weight by 16 to obtain ounces
  let length_in_cm = parseFloat(length) ;
  let breath_in_cm = parseFloat(breath) ;
  let height_in_cm = parseFloat(height) ;



    // determine cost per kg, based on weight ranges and shipping multiplier
    // if (weight_in_kg < 20) {
    //     cost_per_kg = 2 * shipping_cost_multiplier ;
    //   } else if (weight_in_kg > 32) {
    //     cost_per_kg = 20 * shipping_cost_multiplier;
    //   } else {
    //     cost_per_kg = 10 * shipping_cost_multiplier;
    //   }


  // determine cost per length, based on weight ranges and shipping multiplier
  // if (length_in_cm < 20) {
  //   cost_per_cm = 2 * shipping_cost_multiplier ;
  // } else if (length_in_cm > 32) {
  //   cost_per_cm = 10 * shipping_cost_multiplier;
  // } else {
  //   cost_per_cm = 5 * shipping_cost_multiplier;
  // }

  
  // determine cost per breath, based on weight ranges and shipping multiplier
  // if (breath_in_cm < 20) {
  //   cost_per_breath = 2 * shipping_cost_multiplier ;
  // } else if (breath_in_cm > 32) {
  //   cost_per_breath = 10 * shipping_cost_multiplier;
  // } else {
  //   cost_per_breath = 5 * shipping_cost_multiplier;
  // }

    
  // determine cost per height, based on weight ranges and shipping multiplier
  // if (height_in_cm < 20) {
  //   //cost_per_height = 2 * shipping_cost_multiplier ;
  // } else if (height_in_cm > 32) {
  //   //cost_per_height = 10 * shipping_cost_multiplier;
  // } else {
  //   //cost_per_height = 5 * shipping_cost_multiplier;
  // }

//   // determine actual shipping cost of weight * cost per kg.
//   shipping_cost = (cost_per_kg * weight_in_kg * 0.01).toFixed(2);

// determine actual shipping cost of weight * cost per kg.
shipping_cost = (cost_per_kg * (length_in_cm *breath_in_cm  *height_in_cm)/166 ).toFixed(2);
 
  // create object to be returned that includes all values needed to build the display
  return {
    weight_ozs: weight_in_kg,
    weight_kg: weight,
    length_cm:length,
    breath_cm: breath,
    height_cm:height,
    cost: shipping_cost
  };
}
