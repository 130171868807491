import React, {useState} from 'react';
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Modal,
    ModalHeader,
    ModalBody,
    Button,
} from "reactstrap"
//import { useDispatch, useSelector } from 'react-redux';
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb"


// import {
//     getUsers
//   } from "../../store/users/actions";

const Users = () => {
 
    //const dispatch = useDispatch();

    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
 
   // const users = useSelector((state) => state.users.users);

    const toggle = () => {
        setModal(!modal)
      }

    const handleAddUser = () => {
        toggle()
        
        // not in use
        setIsEdit(false)
      }

    return (
        <>
        <div className="page-content">
        <MetaTags>
            <title>Users | Amgray Logistics</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Users" breadcrumbItem="USERS" />

            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <h5 className="card-title mb-4">3 Users</h5>
                    <Col sm="12">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    className="btn me-2 app-button"
                                     onClick={handleAddUser}
                                    style={{background:"#022b51", color:"#fff", padding:"8px 18px"}}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                      Add User
                                    </Button>
                                </div>
                              </Col>

                               {/* Add User Modal start */}
                                  <Modal
                                  isOpen={modal}
                                  toggle={toggle}
                                >
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? "Edit User" : "Add User"}
                                  </ModalHeader>
                                  <ModalBody>
                                    <AvForm
                                    //   onValidSubmit={
                                    //     handleValidFreightSubmit
                                    //   }
                                    >
                                      <Row form>
                                        <Col className="col-12">

                                          <div className="mb-3">
                                            <AvField
                                              name="firstname"
                                              label="First Name"
                                              type="text"
                                              errorMessage="Invalid user name"
                                              validate={{
                                                required: { value: true },
                                              }}
                                            //   value={firstname}
                                              // value={customerList.username || ""}
                                            //   onChange={(e) => setFirstname(e.target.value)}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="lasttname"
                                              label="Last Name"
                                              type="text"
                                              errorMessage="Invalid user name"
                                              validate={{
                                                required: { value: true },
                                              }}
                                            //   value={firstname}
                                              // value={customerList.username || ""}
                                            //   onChange={(e) => setLastname(e.target.value)}
                                            />
                                          </div>

                                          <div className="mb-3">
                                            <AvField
                                              name="phone"
                                              label="Phone Number"
                                              type="text"
                                              errorMessage="Invalid Phone no"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              //value={customerList.phone || ""}
                                            //   onChange={(e) => setPhone(e.target.value)}
                                            //   value={phone}
                                            />
                                          </div>

                                          <div className="mb-3">
                                            <AvField
                                              name="email"
                                              label="Email Address"
                                              type="email"
                                              errorMessage="Invalid Email"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              //value={customerList.email || ""}
                                            //   value={email}
                                            //   onChange={(e) => setEmail(e.target.value)}
                                            />
                                          </div>

                                          <div className="mb-3">
                                            <AvField
                                              name="shippingAddress"
                                              label="Shipping Address"
                                              type="textarea"
                                              errorMessage="Invalid Address"
                                              rows="3"
                                              validate={{
                                                required: { value: true },
                                              }}
                                            />
                                          </div>


                                          <div className="mb-3">
                                            <AvField
                                              name="business"
                                              label="Business name"
                                              type="text"
                                              errorMessage="Invalid Business name"
                                              validate={{
                                                required: { value: true },
                                              }}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">

                                            <button
                                              type="submit"
                                              className="btn save-customer app-button"
                                              style={{background:"#022b51", color:"#fff", padding:"8px 18px"}}
                                            >
                                              Add User
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </AvForm>
                                  </ModalBody>
                                </Modal>
            {/* Add User Modal End */}

                    <div className="table-responsive">
                      <Table className="table table-nowrap align-middle mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email Address</th>
                            <th scope="col">Role</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* {users.map((user, key) => (
                            <tr key={key}>
                              <th scope="row">
                                <span>
                                <div className="text-muted">
                                   {user.firstname}
                                </div>
                              </span>
                                 
                              </th>
                              <td>
                                <div className="text-muted">
                                  {user.email}
                                </div>
                              </td>
                              <td>
                                <h5 className="font-size-14 mb-1">
                                  {user.role}
                                </h5>
                              </td>
                            </tr>
                          ))} */}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
}

export default Users;
