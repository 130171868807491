import React from "react"
import MetaTags from 'react-meta-tags';
import { Card, CardBody, Col, Container, Row, CardTitle,} from "reactstrap"
//import {useSelector, useDispatch } from 'react-redux';

import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min'
import BootstrapTable from "react-bootstrap-table-next"
import trackColumns from "./TrackingColumns"
//Import Breadcrumb
import Breadcrumbs from "../../../components/common/Breadcrumb"


const TrackingDetails = () => {
  //const dispatch = useDispatch();

    // const users = useSelector((state) => state.users.users);
    const data = [
      {id:1, description:"Delivery of a 4.5KVA Mikano generator", quantity:2, price:"200,000"},
      {id:2, description:"Delivery of 12kg package to USA",  quantity:1, price:"175,000"},
      {id:3, description:"Delivery of parcel",  quantity:12, price:"25,000"},
    ]

    

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Track Shipment</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Track Shipment" breadcrumbItem="Track Shipment" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <h5 className="card-title">Shipment Details:</h5>
                  <p className="card-title-desc">Your recent shipping information.</p>

                  <div className="mt-5">
                    <div className="position-relative m-4">
                      <div className="progress" style={{ height: '1px' }}>
                        <div className="progress-bar" role="progressbar" style={{ width: '50%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <button type="button" className="position-absolute top-0 start-0 translate-middle btn btn-sm btn-primary rounded-pill" style={{ width: '2rem', height: '2rem', background:"#022b51"}}>{<i size="" className='bx bxs-plane bx-rotate-90' ></i>}</button>
                      <button type="button" className="position-absolute top-0 start-50 translate-middle btn btn-sm btn-primary rounded-pill" style={{ width: '2rem', height: '2rem', background:"#022b51" }}>{<i size="" className='bx bxs-plane bx-rotate-90' ></i>}</button>
                      <button type="button" className="position-absolute top-0 start-100 translate-middle btn btn-sm btn-secondary rounded-pill" style={{ width: '2rem', height: '2rem', background:"#022b51" }}></button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          <Row>
          <Col xl="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Shipment Details:</CardTitle>
                  <ToolkitProvider
                    keyField="id"
                     data={data || []}
                    columns={trackColumns()}
                    bootstrap4
                  >
                    {toolkitProps => (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                responsive
                                remote
                                bordered={true}
                                striped={false}
                                classes={
                                  "table align-middle table-nowrap table-hover"
                                }
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
            </Row>
        </Container>
      </div>
    </>
  )
}

export default TrackingDetails
