import React from "react"
import ReactApexChart from "react-apexcharts"
const ColumnWithDataLabels = ({paymentHistory}) => {

  let paymentDates = []
  for(let i = 0; i < paymentHistory.length; i++) {
    const newDate = new Date(paymentHistory[i].datePaid);
    let localDate = newDate.toLocaleDateString();
          paymentDates = [...paymentDates, localDate]
  }

  let paymentReceived = []
  for(let i = 0; i < paymentHistory.length; i++) {
  paymentReceived = [...paymentReceived, paymentHistory[i].amountPaid]
  }
  const series = [
    {
      name: "Payment Recieved",
      data: paymentReceived,
    },
  ]
  const options = {
    chart: {
      zoom: { enabled: false },
      toolbar: {show: false},
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#022b51"],
    grid: {
      borderColor: "#f1f1f1",
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: paymentDates,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy",
      },
    },
  }
  return (
    <>
    <ReactApexChart options={options} series={series} type="bar" height={300} />
    <h5 style={{textAlign:"center", color:"444"}}>Monthly Revenue for year 2022</h5>
    </>
  )
}
export default ColumnWithDataLabels
