import React, { useEffect }  from "react"
import MetaTags from 'react-meta-tags';
 import PropTypes from "prop-types"
 import './kyc.css'
 import { useParams, useLocation } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap"
import { useSnackbar } from 'react-simple-snackbar'

//Import Breadcrumb
import Breadcrumbs from "../../../components/common/Breadcrumb"

//Import actions
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, approveUser } from '../../../store/users/actions'


const KycDetails = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { id } = useParams()
  
  const [openSnackbar, closeSnackbar] = useSnackbar()
  const {user} = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(fetchUser(id));
  },[id, dispatch, location]);

// Handle client approval
  const handleApproveUser = (id) => {
  dispatch(approveUser(id, openSnackbar, closeSnackbar))
  window.location.reload(true)
  }


  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>User Details</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="KYC" breadcrumbItem="User Details" />
          <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl="6">
                        <div>
                          <Row>
                            <Col md={{ size: 9, offset: 1 }} xs="9">
                            {/* {isLoading && (
                              <Loader />
                            ) } */}
                            <div>
                                <img
                                 src={user?.idCard}
                                 alt="user"
                                 id="expandedImg1"
                                 className="img-fluid mx-auto d-block img"
                                    />
                                  </div>
                              <div className="text-center">
                              {user?.status==='Pending' && (
                              <Button
                              onClick={() => handleApproveUser(user?._id && user?._id)}
                              style={{background:'#022b51', marginTop:'12px'}}
                                type="button"
                                className="btn  mt-4 me-1"
                              >
                               Approve client
                              </Button>
                            )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <Col xl="6">
                        <div className="mt-4 mt-xl-3">
                          <h6 className="mb-4">
                            User Status :{" "}
                            <span className= {user?.status==='Pending' ? 'text-warning text-uppercase':'text-success text-uppercase'}>{user?.status}</span>
                          </h6>
                          <h6 style={{fontWeight:'bold'}} className="mt-1 mb-3"> User Information</h6>
                          <p className="mb-4">
                            First Name :{" "}
                            <span className="text-muted">{user?.firstName}</span>
                          </p>
                          <p className="mb-4">
                            Last Name :{" "}
                            <span className="text-muted">{user?.lastName}</span>
                          </p>
                          <p className="mb-4">
                            Business Name :{" "}
                            <span className="text-muted">{user?.businessName}</span>
                          </p>
                          <p className="mb-4">
                            Phone Number :{" "}
                            <span className="text-muted">{user?.phone}</span>
                          </p>
                          <p className="mb-4">
                            Email Address :{" "}
                            <span className="text-muted">{user?.email}</span>
                          </p>
                          <p className="mb-4">
                            Preffered Identity :{" "}
                            <span className="text-muted">{user?.prefferedId}</span>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
     
        </Container>
      </div>
    </>
  )
}

KycDetails.propTypes = {
  user: PropTypes.object,
  fetchUser : PropTypes.func,
}

export default KycDetails;