//import axios from 'axios';
import {USER_LOGOUT,ADD_NEW_CLIENT,AUTH, CREATE_PROFILE  } from '../auth/actionTypes';
import * as api from '../../api/index';

export const Registration = (formData, openSnackbar, closeSnackbar, setLoading)=> async(dispatch)=>{
  try {
    //regsister a user
   const {data} =   await api.register(formData)
     dispatch({ type: AUTH, payload: data})
      const { info } = await api.createProfile({firstName: data?.result?.firstName, lastName: data?.result?.lastName, email: data?.result?.email, userId: data?.result?._id, phone: data?.result?.phone, businessName: data?.result?.businessName, avatar: '', contactAddress: ''});
      dispatch({ type: CREATE_PROFILE, payload: info });
      const { clientdata } = await api.addClient({firstName: data?.result?.firstName, lastName: data?.result?.lastName, email: data?.result?.email, userId: data?.clientId, phone: data?.result?.phone, businessName: data?.result?.businessName, contactAddress: ''});
      dispatch({ type: ADD_NEW_CLIENT, payload: clientdata });
      openSnackbar("Sign up successfull")
            // history.push('/user/dashboard')
    window.location.href="/login"

} catch (error) {

openSnackbar(error?.response?.data?.message)
// console.log(error)
setLoading(false)
}
}


export const LoginAction = (formData, openSnackbar, closeSnackbar, setLoading)=> async(dispatch)=>{
  try {
    //login the user
    const { data } = await api.signIn(formData)
    dispatch({ type: AUTH, data})
     openSnackbar("Signin successfully")

} catch (error) {
  openSnackbar(error?.response?.data?.message)
  setLoading(false)
}
}

// Logout Action

export const Logout = ()=>dispatch=>{
     localStorage.removeItem('profile')
    // localStorage.removeItem('profileDetail')
    dispatch({
        type: USER_LOGOUT
    })
}

// Forgot Password
export const forgotPassword =(formData) => async() => {
  try {
      await api.forgotPassword(formData)
  } catch (error) {
      console.log(error)
  }
}

// Reset Password
export const resetPassword =(formData, history) => async() => {
  try {
      await api.resetPassword(formData)
    //  history.push('/user/dashboard')
   console.log("Password chaneged.........")
  } catch (error) {
      alert(error)
  }
}

  