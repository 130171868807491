import PropTypes from 'prop-types'
import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
// Import menuDropdown
 import Notification from "../Topbar/Notification/Notification"
 import ProfileMenu from "../Topbar/ProfileMenu/ProfileMenu";

// Redux Store
import {
  toggleLeftmenu,
  changeSidebarType,
} from "../../../store/actions"

const Header = () => {
  //const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }
  return (
    <>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
            
            <div style={{display:"flex", marginLeft:"30px"}}>
            <Link to="/user/dashboard/freights" style={{marginTop:'24px'}} className="ms-2 btn">Freights </Link>
            {/* <Link to="/user/dashboard/track-freight" style={{marginTop:'24px'}} className="ms-2 btn">Track Freight</Link> */}
            <Link to="/user/dashboard/invoices" style={{marginTop:'24px'}} className="ms-2 btn">Invoices</Link>
            </div>
          </div>
          <div className="d-flex">
            <Notification />
            <ProfileMenu /> 

          </div>
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    layoutType,
    leftMenu,
    leftSideBarType,
  } = state.Layout
  return { layoutType, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  toggleLeftmenu,
  changeSidebarType,
})((Header))
