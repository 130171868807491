
import {ALL_CONSIGNEES, UPDATE_CONSIGNEE, DELETE_CONSIGNEE, ADD_NEW_CONSIGNEE, FETCH_CONSIGNEES_BY_USER, FETCH_CONSIGNEE, START_LOADING, END_LOADING } from './actionTypes'

const consigneesReducer = (state = { isLoading: true, consignees: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
      case ALL_CONSIGNEES:
        return {
          ...state,
          consignees: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
      case FETCH_CONSIGNEES_BY_USER:
        return { ...state, consignees: action.payload };

      case FETCH_CONSIGNEE:
        return { ...state, consignees: action.payload.consignee };
      case ADD_NEW_CONSIGNEE:
        return { ...state, consignees: [...state.consignees, action.payload] };
      case UPDATE_CONSIGNEE:
        return { ...state, consignees: state.consignees.map((consignee) => (consignee._id === action.payload._id ? action.payload : consignee)) };
      case DELETE_CONSIGNEE:
        return { ...state, consignees: state.consignees.filter((consignee) => consignee._id !== action.payload) };
      default:
        return state;
    }
  };

  export default consigneesReducer
