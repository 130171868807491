import React, { useEffect} from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from "prop-types"

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {PaginationProvider} from "react-bootstrap-table2-paginator"
//import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min'
import * as moment from 'moment';

import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/common/Breadcrumb";
// import NoData from '../../SvgIcons/NoData';
import Spinner from "../../../components/Spinner/Spinner";

import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

// import Empty from '../../SvgIcons/NoData';

//Redux Actions
import { deleteInvoice, getInvoices } from '../../../store/invoices/actions';

const Invoices = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const invoices = useSelector(state => state.invoices.invoices)
  const isLoading = useSelector(state => state.invoices.isLoading)

  useEffect(() => {
    dispatch(getInvoices());
    // eslint-disable-next-line
  },[location])


 const toCommas = (value) => {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }


  const editInvoice = (id) => {
    history.push(`/admin/dashboard/edit/invoice/${id}`)
  }

  const openInvoice = (id) => {
    history.push(`/admin/dashboard/invoice/${id}`)
  }

  const handleAddInvoice = () => {
    window.location.href='/admin/dashboard/invoice'
  }


  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  }

if(isLoading) {
  return  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px'}}>
      <Spinner />
  </div>
}

// if(invoices.length === 0) {
//   return  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px'}}>
//     <Empty />
//   <p style={{padding: '40px', color: 'gray'}}>Nothing to display</p>
//   </div>
// }

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
    Showing { from } to { to } of { size } Results
  </span>
);

 //pagination customization
const options  = {
  paginationSize: 4,
  pageStartIndex: 0,
  firstPageText: 'First',
  prePageText: 'Back',
  nextPageText: 'Next',
  lastPageText: 'Last',
  nextPageTitle: 'First page',
  prePageTitle: 'Pre page',
  firstPageTitle: 'Next page',
  lastPageTitle: 'Last page',
  showTotal: true,
  paginationTotalRenderer: customTotal,
  disablePageTitle: true,
  sizePerPageList: [{
    text: '10', value: 10
  }, {
    text: '15', value: 15
  }, {
    text: 'All', value: invoices.length
  }]
};

  const { SearchBar } = Search
  const InvoiceColumns = () => [
    {
      dataField: "invoiceNumber",
      text: "Invoice Number",
      sort: true,
      formatter: (cellContent, row) => (
        <p style={{cursor: 'pointer'}} onClick={() => openInvoice(row._id)}>{row.invoiceNumber}</p>),
    },
    {
      dataField: "client.firstName",
      text: "Client Name",
      sort: true,
      formatter: (cellContent, row) => (
        <p style={{cursor: 'pointer'}} onClick={() => openInvoice(row._id)}>{row.client.firstName} {row.client.lastName}</p>),
    },
   
    {
      dataField: "createdAt",
      text: "Date Created",
      sort: true,
      formatter: (cellContent, row) => ( 
        <p style={{cursor: 'pointer'}} onClick={() => openInvoice(row._id)}>{handleValidDate(row.createdAt)}</p>  
      ),
      
    },
    {
      dataField: "total",
      text: "Amount",
      sort: true,
      formatter: (cellContent, row) => (
        <p style={{cursor: 'pointer'}} onClick={() => openInvoice(row._id)}>{row.currency} {row.total? toCommas(row.total.toFixed(2)): row.total}</p>
        ),
    },
    // {
    //   dataField: "status",
    //   text: "Status",
    //   sort: true,
    //   formatter: (cellContent, row) => (
    //     <button style={checkStatus(row.status)} onClick={() => openInvoice(row._id)}>{row.status}</button>
    //     ),
    // },


    {
      dataField: "action",
      text: "Action",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3">
            <button className=" btn text-success" onClick={() => editInvoice(row._id)}>
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
               <UncontrolledTooltip placement="top" target="edittooltip">
                 Edit
             </UncontrolledTooltip>
             </button>
             <button className=" btn text-danger" onClick={() => dispatch(deleteInvoice(row._id))}>
               <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
               <UncontrolledTooltip placement="top" target="deletetooltip">
                 Delete
             </UncontrolledTooltip>
             </button>
           </div>
         </>
        ),
    },
    
  ]

  const defaultSorted = [{
    dataField: 'order',
    order: 'desc'
  }];

   
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Invoices</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Invoices" breadcrumbItem="Invoices" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(options)}
                    keyField='_id'
                    columns={InvoiceColumns()}
                    data={invoices}
                  >
                    {({ paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={invoices}
                        columns={InvoiceColumns()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>

                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <button 
                                    onClick={handleAddInvoice}
                                   
                                    type="button"
                                    className="btn  mb-2 me-2 app-button"
                                    // onClick={handleOrderClicks}
                                    style={{background:"#022b51", color:"#fff", padding:"10px 20px"}}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                      Create Invoice
                                    </button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="_id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            {/* <Row>
                               {invoices.length === 0  && (
                       <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '30px'}}>
                           <NoData />
                           <p style={{padding: '40px', color: 'gray', textAlign: 'center'}}>No invoice yet. Click on the Add button to create an invoice</p>
                              </div>)}
                            </Row> */}
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

Invoices.propTypes = {
    getInvoices: PropTypes.func,
 
}
export default Invoices