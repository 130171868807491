import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { useSnackbar } from 'react-simple-snackbar'
//import Inputs from "../components/Inputs";
// Redux
import { LoginAction } from "../../store/auth/actions";

import { Row, Col, CardBody, Card, Container, Form, Label, Input, Spinner } from "reactstrap"

// import images
import Logo from "../../assets/images/amgray-update-logo3.png"

const initialState = { name: '', email: '', password: '', confirm: ''}

const Login = () => {
  const [form, setForm] = useState(initialState)
  const dispatch = useDispatch()
  // const errors = useSelector(state=>state.errors)
  const [loading, setLoading] = useState(false)
  const [openSnackbar, closeSnackbar] = useSnackbar()

  const onChangeHandler = (e)=>{
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (e)=>{
    e.preventDefault();
    dispatch(LoginAction(form, openSnackbar, closeSnackbar, setLoading))
    setLoading(true)
    }

     //console.log(closeSnackbar)

 
  return (
    <>
     <MetaTags>
          <title>Login</title>
        </MetaTags>
      <div className="home-btn d-none d-sm-block">
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft">
                  <Row>
                  <Col className="align-self-end">
                      <img src={Logo} alt="Amgray logo" className="img-fluid" />
                    </Col>
                  </Row>
                  <Row>
                  <Col style={{justifyContent:"center", display:"flex"}}>
                      <div className="p-4">
                        <h5 className="ml-2">Login to your dashboard</h5>
                      </div>
                    </Col>
                    </Row> 
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                  <Form onSubmit={handleSubmit}>
                    <Row>
                    <Col md={12}>
                        <div className="mb-3">
                        {/* { errors.email === null && <span  style={{color:"red"}}>{errors.email}</span>} */}
                          <Label htmlFor="formrow-email-Input">Email</Label>
                          <Input
                            type="email"
                            className="form-control"
                            name="email"
                         onChange={onChangeHandler}
                          />
                        </div>
                        </Col>
        
                    </Row>
                
                    <Row>
                    <Col md={12}>
                        <div className="mb-3">
                          <Label htmlFor="password">Password</Label>
                          <Input
                            type="password"
                            className="form-control"
                            name="password"
                            onChange={onChangeHandler}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="mt-4 d-grid">
                    {loading ?  <Spinner style={{color:"#022b51", margin:'auto', justifyContent:'center'}} animation="border" /> :
                        <button
                          className="btn btn-block"
                          type="submit"
                          style={{background:"#022b51", color:"#fff", paddingTop:'0.85rem'}}
                        >
                          Login to your dashboard
                        </button>
                         }
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>     

                       
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don&#39;t have a Amgray Logistics account ?{" "}
                  <Link
                    to="register"
                    className="fw-medium"
                    style={{color:"#022b51", fontWeight:"bold"}}
                  >
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Amgray Logistics
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}



export default withRouter(Login);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
//   loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}