import * as api from '../../api/index'
//import {axiosInstance} from '../../api/config'
import {ALL_FREIGHTS, ADD_NEW_FREIGHT, UPDATE_FREIGHT, DELETE_FREIGHT, FETCH_FREIGHTS_BY_USER, FETCH_FREIGHT, START_LOADING, END_LOADING } from './actionTypes'

export const getFreight = (id) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.fetchFreight(id);
      dispatch({ type: FETCH_FREIGHT, payload: { freight: data } });

    } catch (error) {
      console.log(error);
    }
  };

  export const getFreights = () => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING })
      const { data } = await api.fetchFreights();
      dispatch({ type: ALL_FREIGHTS, payload: data });
      dispatch({ type: END_LOADING })
  
    } catch (error) {
      console.log(error);
    } };


export const getFreightsByUser =(searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING })

     const  { data: { data } } = await api.fetchFreightsByUser(searchQuery)
    //const  { data } = await axiosInstance.get('/users');
  
      dispatch({ type: FETCH_FREIGHTS_BY_USER, payload: data });
      dispatch({ type: END_LOADING })
    } catch (error) {
      //console.log("Checking Error",error.response)
      
    }
  }


export const createFreight =(freight, openSnackbar) => async (dispatch) => {

    try {
        const { data } = await api.addFreight(freight)
        dispatch({ type: ADD_NEW_FREIGHT, payload: data })
         openSnackbar("Freight updated successfully")

    } catch (error) {
        console.log(error)
    }
}

export const requestFreight =(freight, openSnackbar) => async (dispatch) => {

  try {
      const { data } = await api.addFreight(freight)
      dispatch({ type: ADD_NEW_FREIGHT, payload: data })
       openSnackbar("Your request has been placed successfully")

  } catch (error) {
      console.log(error)
  }
}


export const updateFreight =(id, freight, openSnackbar) => async (dispatch) => {

    const { data } = await api.updateFreight(id, freight)
    dispatch({ type: UPDATE_FREIGHT, payload: data })
     openSnackbar("Freight updated successfully")
    try {
        
    } catch (error) {
        console.log(error)
    }
}

export const deleteFreight =(id, openSnackbar) => async (dispatch) => {
    try {
        await api.deleteFreight(id)
        dispatch({type: DELETE_FREIGHT, payload: id})
         openSnackbar("Freight deleted successfully")
    } catch (error) {
        console.log(error)
    }
}