import React from 'react';

const TermsOfUse = () => {
    return (
        <div>
            <h2>This is terms of Use page</h2>
        </div>
    );
}

export default TermsOfUse;
