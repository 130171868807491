import React from 'react';
 import { toCommas } from '../../../utils/commas'
import { Row, Col, Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import { useSelector } from 'react-redux'

const Earning = ({paymentHistory}) => {
  const { invoices} = useSelector((state) => state?.invoices)

  let paymentDates = []
  for(let i = 0; i < paymentHistory.length; i++) {
    const newDate = new Date(paymentHistory[i].datePaid);
    let localDate = newDate.toLocaleDateString();
          paymentDates = [...paymentDates, localDate]
  }

  let paymentReceived = []
  for(let i = 0; i < paymentHistory.length; i++) {
  paymentReceived = [...paymentReceived, paymentHistory[i].amountPaid]
  }

    // Total Paid
    let totalPaid = 0
    for(let i = 0; i < invoices.length; i++) {
        if(invoices[i].totalAmountReceived !== undefined) {
            totalPaid += invoices[i].totalAmountReceived
        }
        
    }

    const data = {
        series: [
          {
            name: "Payment Recieved",
            data: [31, 40, 36, 51, 49, 72, 69, 56, 68, 82, 68, 76],
          },
        ],
        options: {
          chart: {
            toolbar: "false",
            dropShadow: {
              enabled: !0,
              color: "#000",
              top: 18,
              left: 7,
              blur: 8,
              opacity: 0.2,
            },
          },
          dataLabels: {
            enabled: !1,
          },
          colors: ["#022b51"],
          stroke: {
            curve: "smooth",
            width: 3,
          },
        },
      }
    return (
        <>
        <Col xl="6">
          <Card>
            <CardBody>
              <div className="clearfix">
                <div className="float-end">
                  <div className="input-group input-group">
                    <select className="form-select form-select-sm">
                      <option defaultValue="JA">Jan</option>
                      <option value="DE">Dec</option>
                      <option value="NO">Nov</option>
                      <option value="OC">Oct</option>
                    </select>
                    {/* <div className="input-group-append"> */}
                      <label className="input-group-text">Month</label>
                    {/* </div> */}
                  </div>
                </div>
                <h4 className="card-title mb-4">Earnings</h4>
              </div>

              <Row>
                <Col lg="4">
                  <div className="text-muted">
                    <div className="mb-4">
                      <p>This month</p>
                      <h4>$ {toCommas(totalPaid.toFixed(2))}</h4>
                    </div>
                   
                  </div>
                </Col>

                <Col lg="8">
                  <div id="line-chart"  dir="ltr">
                    <ReactApexChart
                      series={data.series}
                      options={data.options}
                      type="line"
                      height={320}
                      className="apex-charts"
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </>
    );
}

export default Earning;