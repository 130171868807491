import React, { useState} from 'react';
import MetaTags from 'react-meta-tags';

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Input,
  Form,
  Label
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/common/Breadcrumb";

import { calculate_shipping } from './main';
const initialState = {
  weight_kg: '',
  length_cm:'',
  breath_cm:'',
  height_cm:'',
  cost: '0'
}

const ShippingCalculator = () => {
const [data, setData] = useState(initialState);

const updateResults = (e, type) => {
  const current_weight = (type === 'weight') ? e.target.value : data.weight_kg;
  const current_length = (type === 'length') ? e.target.value : data.length_cm;
  const current_breath = (type === 'breath') ? e.target.value : data.breath_cm;
  const current_height = (type === 'height') ? e.target.value : data.height_cm;

  const {
    weight_kg,
    length_cm,
    breath_cm,
    height_cm,
    cost
  } = calculate_shipping(current_weight, current_length, current_breath, current_height);

setData({
      weight_kg: weight_kg,
      length_cm:length_cm,
      breath_cm: breath_cm,
      height_cm: height_cm,
      cost: cost
})
}

  return (
    <>
    <div className="page-content">
      <MetaTags>
        <title>Shipping Calculator</title>
      </MetaTags>
      <Container fluid={true}>
          
        <Breadcrumbs title="Shipping Calculator" breadcrumbItem="Shipping Calculator" />
       <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Dimensional Weight Calculator </CardTitle>

                <Form>
                    <Row>
                      <Col  lg="2">
                        <div className="mb-3">
                          <Label htmlFor="weight">Weight(W)</Label>
                          <Input
                           id="shipping_weight"
                           name="weight_kg"
                           placeholder="Weight in kg"
                           value={data.weight_kg}
                           onChange={ (event) => updateResults(event, 'weight') }
                            type="number" onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()}
                            className="form-control"
                          />
                        </div>
                      </Col>
                      <Col  lg="2">
                        <div className="mb-3">
                          <Label htmlFor="length">Length(L)</Label>
                          <Input

                            id="shipping_length"
                            name="length_cm"
                            placeholder="Length in cm"
                            value={data.length_cm}
                            onChange={ (event) => updateResults(event, 'length') }
                            type="number" 
                            onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()}
                            className="form-control"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col  lg="2">
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputCity">Breadth(B)</Label>
                          <Input
                          id="shipping_breath"
                          name="breath_cm"
                          placeholder="Breath in cm"
                          value={data.breath_cm}
                          onChange={ (event) => updateResults(event, 'breath') }
                          type="number" 
                           onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()}
                            className="form-control"
                          />
                        </div>
                      </Col>
                      <Col  lg="2">
                        <div className="mb-3">
                          <Label htmlFor="height">Height(H)</Label>
                          <Input
                           id="shipping_height"
                           name="height_cm"
                           placeholder="Height in cm"
                           value={data.height_cm}
                           onChange={ (event) => updateResults(event, 'height') }
                             type="number" 
                             onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()}
                            className="form-control"
                          />
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="preffereddimension">Preffered Dimensional WIdth</Label>
                          <p className="weight">{`${data.weight_kg} x ${data.length_cm} x ${data.breath_cm}`}</p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="price">Total Cost:</Label>
                          <h5 className="weight"> $ {data.cost}</h5>
                        </div>
                      </Col>
                    </Row>
                  </Form>
              </CardBody>
            </Card>
          </Col>
          </Row> 
          </Container>  
    </div>
  </>
  );
}

export default ShippingCalculator;
