import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Container,Input, Label, Form, Spinner  } from "reactstrap"
import React, { useState } from "react";
import { useDispatch, useSelector} from "react-redux";
import { Link} from "react-router-dom";
import Uploader from "./Uploader";
// Action
import { Registration} from '../../store/auth/actions'
// import images
import Logo from "../../assets/images/amgray-update-logo3.png"
import { useSnackbar } from 'react-simple-snackbar'

const initialState = {firstName:'', lastName:'', businessName:'', email: '', password: '', confirm: '', phone:'', prefferedId:'', idCard:'', isChecked:''}
const Register = () => {
  const errors = useSelector(state=>state.errors)
  const [openSnackbar, closeSnackbar] = useSnackbar()
  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();

   const handleOnCheck = () => {
    setIsChecked(!isChecked)
  };

  const onChangeHandler = (e)=>{
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
     
    })
    //  handleOnCheck()
  }

  const handleSubmit = (e)=>{
  e.preventDefault();
  dispatch(Registration(formData, openSnackbar, closeSnackbar, setLoading))
  setLoading(true)
  }

  return (
    <>
     <MetaTags>
          <title>Register</title>
        </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft">
                  <Row>
                    <Col className="align-self-end">
                      <img src={Logo} alt="Amgray logo" className="img-fluid" />
                    </Col>
                  </Row>
                  <Row>
                  <Col style={{justifyContent:"center", display:"flex"}}>
                      <div className="p-4">
                        <h5 className="ml-2">Let's get you started</h5>
                      </div>
                    </Col>
                    </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                  <Form onSubmit={handleSubmit}>
                    <Row>
                    <div className="mb-3">
                      <Label htmlFor="name">First Name</Label>
                      <Input
                        type="text"
                        className="form-control"
                        name="firstName"
                        onChange={onChangeHandler}
                      />
                    </div>
                    {<span style={{color:"red"}}>{errors.firstName}</span>}
                    </Row>
                    <Row>
                    <div className="mb-3">
                      <Label htmlFor="name">Last Name</Label>
                      <Input
                        type="text"
                        className="form-control"
                        name="lastName"
                        onChange={onChangeHandler}
                      />
                    </div>
                    {<span style={{color:"red"}}>{errors.lastName}</span>}
                    </Row>
                    <Row>
                    <div className="mb-3">
                      <Label htmlFor="name">Business Name</Label>
                      <Input
                        type="text"
                        className="form-control"
                        name="businessName"
                        onChange={onChangeHandler}
                      />
                    </div>
                    {<span style={{color:"red"}}>{errors.businessName}</span>}
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Email</Label>
                          <Input
                            type="email"
                            className="form-control"
                            name="email"
                            onChange={onChangeHandler}
                          />
                        </div>
                        {<span style={{color:"red"}}>{errors.email}</span>}
                        <Col>
                        <div className="mb-3">
                      <Label htmlFor="phone">Phone Number</Label>
                      <Input
                        type="text"
                        className="form-control"
                        name="phone"
                        onChange={onChangeHandler}
                      />
                    </div>
                    {<span style={{color:"red"}}>{errors.phone}</span>}
                        </Col>
                        <h6>Select your prefered ID</h6>
                    <Row>
                      <Col>
                        <div>
                          <select   name="prefferedId" defaultValue="0" className="form-select"  onChange={onChangeHandler}>
                            <option>Preffered ID</option>
                            <option name="prefferedId" value="International Passport">International Passport</option>
                            <option name="prefferedId" value="NIN">NIN</option>
                            <option name="prefferedId" value="Voter's Card">Voter's Card</option>
                          </select>
                        </div>
                        {<span style={{color:"red"}}>{errors.prefferedId}</span>}
                      </Col>
                      </Row>
                      <Col>
                        <div className="mt-4">
                          <div>
                            <Uploader formData={formData} setFormData={setFormData} />
                          </div>
                        </div>
                        {<span style={{color:"red"}}>{errors.idCard}</span>}
                      </Col>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3 mt-4">
                          <Label htmlFor="password">Password</Label>
                          <Input
                            type="password"
                            className="form-control"
                            name="password"
                            onChange={onChangeHandler}
    
                          />
                        </div>
                        {<span style={{color:"red"}}>{errors.password}</span>}
                      </Col>
                     
                      <Col md={12}>
                        <div className="mb-3">
                          <Label htmlFor="password">Confirm Password</Label>
                          <Input
                            type="password"
                            className="form-control"
                            name="confirm"
                            onChange={onChangeHandler}
                
                          />
                        </div>
                      </Col>
                      {<span style={{color:"red"}}>{errors.confirm}</span>}
                      </Row>
                       
                      <div className="mt-4 text-center">
                        <p className="mb-4">
                          By registering you agree to Amgray Logistics{" "}
                          <a href="https://amgraylogistics.com/terms-condition/" target='_blank' style={{color:"#022b51", fontWeight:"bold"}} className="" rel="noreferrer">
                            Terms of Use
                            <input
                              style={{marginLeft:35}} 
                            className="form-check-input"
                            type="checkbox"
                              checked={isChecked}
                            onChange={onChangeHandler}
                            name="isChecked"
                            onClick={handleOnCheck}
                            
                          />
                          </a>
                          <br/>
                          {errors.isChecked && <span style={{color:"red"}}>{errors.isChecked}</span>}
                        </p>                      
                      </div>
                      <div className="d-grid">
                       {loading ?  <Spinner style={{color:"#022b51", margin:'auto', justifyContent:'center'}} animation="border" /> :
                        <button
                        disabled={!isChecked}
                          className="btn btn-block"
                          type="submit"
                          style={{background:"#022b51", color:"#fff", paddingTop:'0.75rem',paddingBottom:'0.75rem'}}
                        >
                          Register
                        </button>
                        }
                      </div>
                      </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login"  style={{color:"#022b51", fontWeight:"bold"}} className="font-weight-medium">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Amgray Logistics{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

Register.propTypes = {
  user: PropTypes.any,
}

export default Register;
