import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import Person from '@material-ui/icons/Person';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 900,
    // backgroundColor: "#EEEEEE",
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
}));

export default function ProfileDetail({ profiles }) {
  const classes = useStyles();

  return (
    <>
    <div style={{display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    borderBottom: 'solid 1px #dddddd',
    paddingBottom: '20px'
    }}>
      <Avatar alt={profiles?.businessName} src={profiles.avatar} className={classes.large} />
    </div>
        <List className={classes.root}>
        <ListItem style={{borderBottom: 'solid 1px #e6e6e6'}} >
                <Person style={{marginRight: '20px', color: '022b51'}} />
                <ListItemText primary="First Name" />
            <ListItemText style={{justifyContent:'right', display:'flex', marginLeft:'0px'}} primary={profiles?.firstName} />
          </ListItem >
          <ListItem style={{borderBottom: 'solid 1px #e6e6e6'}} >
                <Person style={{marginRight: '20px', color: '022b51'}} />
                <ListItemText primary="Last Name" />
            <ListItemText style={{justifyContent:'right', display:'flex', marginLeft:'0px'}} primary={profiles?.lastName} />
          </ListItem >
          <ListItem style={{borderBottom: 'solid 1px #e6e6e6'}} >
            <BusinessCenterIcon style={{marginRight: '20px', color: '022b51'}} />
            <ListItemText primary="Business Name" />
            <ListItemText style={{justifyContent:'right', display:'flex'}} primary={profiles?.businessName} />
          </ListItem>

          <ListItem style={{borderBottom: 'solid 1px #e6e6e6'}} >
            <LocationOnIcon style={{marginRight: '20px', color: '022b51'}} />
            <ListItemText primary="Contact Address" />
            <ListItemText style={{justifyContent:'right', display:'flex'}} primary={profiles?.contactAddress} />
          </ListItem>

          <ListItem style={{borderBottom: 'solid 1px #e6e6e6'}} >
            <PhoneInTalkIcon style={{marginRight: '20px', color: '022b51'}} />
            <ListItemText primary="Phone Number" />
            <ListItemText style={{justifyContent:'right', display:'flex'}} primary={profiles?.phone} secondary="" />
          </ListItem>

          <ListItem style={{borderBottom: 'solid 1px #e6e6e6'}}>
            <AlternateEmailIcon style={{marginRight: '20px', color: '022b51'}} />
            <ListItemText primary="Email Address" />
            <ListItemText style={{justifyContent:'right', display:'flex'}} primary={profiles?.email} />
          </ListItem>
        </List>
    </>
  );
}
