import React, {useEffect} from 'react'
import { Container, Row, Col } from "reactstrap"
import MetaTags from 'react-meta-tags';
// import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
// import Empty from '../../SvgIcons/NoData';
import Spinner from '../../../components/Spinner/Spinner';

//Import Breadcrumb
import Breadcrumbs from "../../../components/common/Breadcrumb"
//Import Components
 import CardWelcome from "./card-welcome"
 import MiniWidget from "./mini-widget"
 import Earning from "./earning"
 import SalesAnalytics from "./sales-analytics";

 // Actions
 import { getInvoices } from '../../../store/invoices/actions';
 import {getFreights} from '../../../store/freights/actions';

const Dashboard = () => {

  const location = useLocation()
  const dispatch = useDispatch()
  const { invoices, isLoading } = useSelector((state) => state?.invoices)
  const {freights} = useSelector((state) => state.freights);
  const overDue = invoices?.filter((invoice) => invoice.dueDate <= new Date().toISOString());

  let paymentHistory = []
  for(let i = 0; i < invoices.length; i++) {
      let history = []
      if(invoices[i].paymentRecords !== undefined) {
          history = [...paymentHistory, invoices[i].paymentRecords]
          paymentHistory = [].concat.apply([], history);
      }
      
  }

    //sort payment history by date
  //   const sortHistoryByDate =  paymentHistory.sort(function(a, b) {
  //     var c = new Date(a.datePaid);
  //     var d = new Date(b.datePaid);
  //     return d-c;
  // });

  // Total Paid
  // let totalPaid = 0
  // for(let i = 0; i < invoices.length; i++) {
  //     if(invoices[i].totalAmountReceived !== undefined) {
  //         totalPaid += invoices[i].totalAmountReceived
  //     }
      
  // }

  // Total Amount
  // let totalAmount = 0
  // for(let i = 0; i < invoices.length; i++) {
  //     totalAmount += invoices[i].total
  // }

   
  useEffect(() => {
    dispatch(getInvoices());
    // eslint-disable-next-line
}, [location, dispatch]);

// Freights
useEffect(() => {
  dispatch(getFreights());
// eslint-disable-next-line react-hooks/exhaustive-deps
},[location, dispatch])


const unpaidInvoice = invoices?.filter((invoice) => invoice.status === 'Unpaid')
const paid = invoices?.filter((invoice) => invoice.status === 'Paid')
const partial = invoices?.filter((invoice) => invoice.status === 'Partial')


if(isLoading) {
  return  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px'}}>
      <Spinner />
  </div>
}

// if(invoices.length === 0) {
//   return  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px'}}>
//     <Empty />
//   <p style={{padding: '40px', color: 'gray'}}>Nothing to display</p>
//   </div>
// }

    const dashboardData = {
      reports: [
          {
            icon: "bx bxs-package",
            title: "Freights",
            value: `${freights.length}`,
          },
          {
            icon: "bx bxs-receipt",
            title: "Total Invoices",
            value: `${invoices.length}`,
          },
          {
            icon: "bx bx-money",
            title: "Total Paid Invoices",
            value: `${paid.length}`,
          },
          {
            icon: "bx bxs-wallet",
            title: "UnPaid Invoices",
            value: `${unpaidInvoice.length}`,
          },
          {
            icon: "bx bxs-wallet-alt",
            title: "Partially Paid Invoices",
            value: `${partial.length}`,
          },
          
          {
            icon: "bx bxs-report",
            title: "Over Due",
            value: `${overDue.length}`,
          },
         
        ]
  };
    return (
        <>
        <div className="page-content">
          <MetaTags>
            <title>Amgray Logistics</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />

            <Row>
              {/* welcome card */}
              <CardWelcome />
              <Col xl="12">
                <Row>
                  {/*mimi widgets */}
                  <MiniWidget reports={dashboardData.reports} />
                </Row>
              </Col>
            </Row>

            <Row>
              {/* earning */}
              <Earning paymentHistory={paymentHistory}/>

              {/* sales anytics */}
              <SalesAnalytics paymentHistory={paymentHistory} />
            </Row>
          </Container>
        </div>
      </>
    );
}

export default Dashboard;










