import React, { useEffect } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from "prop-types"
import {
  Card, CardBody, Col, Container, Row,
} from "reactstrap"

//import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min'
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
//import { useSnackbar } from 'react-simple-snackbar'

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom'
import { fetchUsers } from '../../../store/users/actions'
//Import Breadcrumb
import Breadcrumbs from "../../../components/common/Breadcrumb";

const Kyc = () => {
  const dispatch = useDispatch();
  const location = useLocation()
  const history = useHistory()

const {users} = useSelector((state) => state.users);

useEffect(() => {
  dispatch(fetchUsers());
// eslint-disable-next-line react-hooks/exhaustive-deps
},[location, dispatch])


const openUser = (id) => {
    history.push(`/admin/dashboard/users/${id}`)
  }

  //pagination customization
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing { from } to { to } of { size } Results
    </span>
  );
  
   //pagination customization
  const options  = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
      text: '10', value: 10
    }, {
      text: '15', value: 15
    }, {
      text: 'All', value: users.length
    }]
  };

  const ClientColumns = [
    {
      text: "First Name",
      dataField: "firstName",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{row.firstName}</p>
          
        </>
      ),
    },
    {
      text: "Last Name",
      dataField: "lastName",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{row.lastName}</p>
          
        </>
      ),
    },
    {
      text: "Phone Number",
      dataField: "phone",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{row.phone}</p>
          
        </>
      ),
    },
   
    {
      text: "Email Address",
      dataField: "email",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p  className="mb-0">{row.email}</p>
        </>
      ),
    },
    {
        text: "Preffered ID",
        dataField: "prefferedId",
        sort: true,
        formatter: (cellContent, row) => (
          <>
            <p className="mb-0">{row.prefferedId}</p>
          </>
        ),
      },
    {
      text: "Business Name",
      dataField: "businessName",
      sort: true,
  
      formatter: (cellContent, row) => (
        <>
          <p className="mb-0">{row.businessName}</p>
        </>
      ),
    },
    {
        dataField: "_id",
        text: "View Details",
        sort: false,
        formatter: (cellContent, row) => (
            <button className="btn-sm btn-rounded app-button"  type="button" style={{background:"#022b51", color:"white"}} onClick={() => openUser(row._id)}>
            View Details
          </button>
        ),
      },
    {
        text: "Status",
        dataField: "status",
        sort: true,
        formatter: (cellContent, row) => (
          <>
            <p className={row.status==='Pending' ? 'text-warning text-uppercase':'text-success text-uppercase'} style={{color:'#F29339', fontSize:'14px'}}>{row.status}</p>
          </>
        ),
      },
  ]
  
  const { SearchBar } = Search

  return (
    <>
    <div className="page-content">
        <MetaTags>
          <title>Amgray Logistics | KYC</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Users" breadcrumbItem="Users" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(options)}
                    keyField='_id'
                    columns={ClientColumns}
                    data={users}
                  >
                    {({ paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={users || []}
                        columns={ClientColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                     keyField="id"
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row> 
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

Kyc.propTypes = {
  users: PropTypes.array,
 
}
export default Kyc;