import React from 'react'
import { Container, Row, Col } from "reactstrap"
import MetaTags from 'react-meta-tags';
//Import Breadcrumb
import Breadcrumbs from "../../../components/common/Breadcrumb"
//Import Components
 import CardWelcome from "./card-welcome"
 import MiniWidget from "./mini-widget"
 //import MyOrders from './MyOrders';
//import ShippingCalculator from './../Calculator/Calculator';

const Dashboard = () => {
    
    const dashboardData = {
      reports: [
          {
            icon: "bx bx-dollar",
            title: "Dollar",
            value: "₦ 580",
          },
          {
            icon: "bx bx-pound",
            title: "Pound",
            value: "₦ 700",
          },
          {
            icon: "bx bx-euro",
            title: "Euro",
            value: "₦ 650",
          },
         
        ]
  };


    return (
        <>
        <div className="page-content">
          <MetaTags>
            <title>Amgray Logistics</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />

            <Row>
              {/* welcome card */}
              <CardWelcome />

              <Col xl="8">
                <Row>
                  {/*mimi widgets */}
                  <MiniWidget reports={dashboardData.reports} />
                </Row>
              </Col>
            </Row>
          
            {/* <Row>
              <ShippingCalculator />
            </Row> */}
           
          </Container>
        </div>
      </>
    );
}

export default Dashboard;










